const DEFAULT_LANGUAGE = 'en';

export const getLocaleLottie = (name: string, language: string) => {
  let lottie;
  try {
    lottie = require(`../../assets/lottie/${language}/${name}.json`);
  } catch {
    lottie = require(`../../assets/lottie/${DEFAULT_LANGUAGE}/${name}.json`);
  }
  return lottie;
};
