import { gql } from '@apollo/client';

export const getOperationDetails = gql`
  query GetOperationDetails($operationId: String!, $tenantId: [String!]) {
    operationDetails: getOperationDetails(
      operationId: $operationId
      tenantId: $tenantId
    ) {
      operationId
      type
      details {
        ... on AuthenticationDetails {
          statusChangeList {
            status
            comment
            previousStatus
          }
          extServiceManualChecklist {
            isChecked
            name
          }
        }
        ... on OnboardingDetails {
          operationResults {
            status
            reason
          }
          statusChangeList {
            status
            comment
            previousStatus
          }
          type
          extServiceManualChecklist {
            name
            isChecked
          }
        }
      }
    }
  }
`;
