import { gql } from '@apollo/client';

export const searchOperations = gql`
  query searchOperations(
    $status: [EntityStatus!]
    $fromTimestamp: String
    $limit: Int
    $text: String
    $cursor: String
    $family: [OperationType!]
    $toTimestamp: String
    $currentStep: [String!]
    $orderBy: String
  ) {
    operations: searchOperations(
      after: $cursor
      parameters: {
        status: $status
        fromTimestamp: $fromTimestamp
        limit: $limit
        text: $text
        include: $family
        toTimestamp: $toTimestamp
        currentStep: $currentStep
        orderBy: $orderBy
      }
    ) {
      edges {
        node {
          deleted
          operationId
          family: type
          customerId
          creationTime
          lastUpdateTime
          currentStep
          steps
          stepsFlow
          status
          results {
            type
            status
            reason
          }
          assets {
            type
            url
            contentType
          }
          wallet {
            type
            walletId
            source
          }
          credentials {
            type
            walletId
            credentialId
            credentialType
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
` as any;

export const getOperation = gql`
  query getOperation($tenantId: [String!], $id: String!) {
    operation: getOperationDetails(operationId: $id, tenantId: $tenantId) {
      family: type
      currentStep
      operationId
      lastUpdateTime
      creationTime
      steps
      status
      results {
        type
        status
        reason
      }
      details {
        ... on AuthenticationDetails {
          assets {
            url
            contentType
            type
            source
          }
          device {
            type
            model
            osVersion
            brand
            deviceId
            browser
            deviceType
            engine
            osName
            isRooted
            isEmulated
            hasSIMCard
          }
          coordinates {
            latitude
            longitude
          }
          facialAuthenticateResult {
            similarity
            authStatus
            source
          }
          facialLivenessResult {
            diagnostic
            description
          }
          ocrData {
            personalInformation {
              name
              surname
              nationality
              documentNumber
              birthDate
              birthPlace
              address
              city
            }
            source
            default
            documentDataFields {
              key
              value
            }
            documentImages {
              type
              url
              contentType
              source
            }
          }
          wallet {
            type
            walletId
            source
          }
          credentials {
            type
            walletId
            credentialId
            credentialType
          }
        }
        ... on OnboardingDetails {
          coordinates {
            latitude
            longitude
          }
          facialAuthenticateResult {
            similarity
            authStatus
            source
          }
          facialLivenessResult {
            diagnostic
            description
          }
          device {
            type
            model
            osVersion
            brand
            deviceId
            browser
            deviceType
            engine
            osName
            isRooted
            isEmulated
            hasSIMCard
          }
          ocrData {
            personalInformation {
              name
              surname
              nationality
              documentNumber
              birthDate
              birthPlace
              address
              city
            }
            source
            default
            documentDataFields {
              key
              value
            }
            documentImages {
              type
              url
              contentType
              source
            }
          }
          securityData {
            verificationStatus
            scanStatus
            scanSource
            dataPositions
            documentValidation
            hologramValidation
            mrzCodeVerification
            featuresVerification
            signatureVerification
            documentType
            documentSubType
            country
            rejectReason
            microprintVerification
          }
          securityInfoDataList {
            mode
            data
            succeed
          }
          assets {
            url
            contentType
            type
            source
          }
          wallet {
            walletId
            type
            source
          }
          credentials {
            type
            walletId
            credentialId
            credentialType
          }
        }
      }
    }
  }
` as any;

export const getOperationDetails = gql`
  query GetOperationDetails($operationId: String!, $tenantId: [String!]) {
    operationDetails: getOperationDetails(
      operationId: $operationId
      tenantId: $tenantId
    ) {
      operationId
      type
      details {
        ... on AuthenticationDetails {
          statusChangeList {
            status
            comment
            previousStatus
            timestamp
          }
          extServiceManualChecklist {
            isChecked
            name
          }
        }
        ... on OnboardingDetails {
          operationResults {
            status
            reason
          }
          statusChangeList {
            status
            comment
            previousStatus
            timestamp
          }
          type
          extServiceManualChecklist {
            name
            isChecked
          }
        }
      }
    }
  }
` as any;
