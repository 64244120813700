import { useCallback, useEffect, useState } from 'react';
import axios, { CancelTokenSource } from 'axios';
import { useCommon } from '../providers';
import { MimeType } from '../state/model';

export function useLoadAsset(assetUrl?: string, contentType?: MimeType) {
  const [hasError, setError] = useState<boolean>(false);
  const [resourceUrl, setResourceUrl] = useState<string | undefined>();
  const [loadingResource, setLoadingResource] = useState<boolean>(false);
  const [cancelTokenSource, setCancelToken] =
    useState<CancelTokenSource | null>(null);

  const { token } = useCommon();

  const loadAsset = async (assetUrl: string) => {
    try {
      const response = await axios
        .get(assetUrl, {
          cancelToken: cancelTokenSource?.token,
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => response.data);
      const objectUrl = URL.createObjectURL(response);
      if (
        contentType &&
        [MimeType.jpeg, MimeType.jpg, MimeType.png].includes(contentType)
      ) {
        // Check the image
        const tester = new Image();
        tester.onload = () => {
          setResourceUrl(objectUrl);
        };
        tester.onerror = () => {
          setError(true);
        };
        tester.src = objectUrl;
      } else {
        setResourceUrl(objectUrl);
      }
    } catch {
      setError(true);
    }
    setLoadingResource(false);
    setCancelToken(null);
  };

  useEffect(() => {
    if (assetUrl && cancelTokenSource) {
      loadAsset(assetUrl);
    }
  }, [assetUrl, cancelTokenSource]);

  const cancelLoadAsset = useCallback(() => {
    cancelTokenSource && cancelTokenSource.cancel();
  }, [cancelTokenSource]);

  useEffect(() => {
    setError(false);
    setLoadingResource(true);
    setResourceUrl(undefined);
    if (assetUrl && contentType) {
      setCancelToken(axios.CancelToken.source());
    }
  }, [assetUrl]);

  return {
    hasError,
    resourceUrl,
    loadingResource,
    cancelSource: cancelLoadAsset,
  };
}
