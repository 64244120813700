import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { usePermissions } from '../hooks';
import { SCOPES } from '../state/model';

type Props = {
  children: ReactNode;
  scopes: SCOPES[];
  redirect?: boolean;
};

export const PermissionsGate = ({ children, scopes, redirect }: Props) => {
  const { permissionGranted } = usePermissions(scopes);

  if (!permissionGranted) {
    return redirect ? <Navigate to="/404" /> : null;
  }

  return <>{children}</>;
};
