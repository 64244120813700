import { ChangeEvent, useEffect, useState } from 'react';
import {
  Dropdown,
  DropdownOption,
  FlexContainer,
  Input,
} from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import Country from './country.json';
import { years } from './dataRules';
import { ModalIPStyles } from './Styles';

type Props = {
  show?: boolean;
  onChangeShow(show: boolean): void;
  className?: string;
  onSave(
    type: string,
    country: string,
    year: string,
    description: string
  ): void;
};

type dataModalDocument = {
  type: string;
  country: string;
  year: string;
  description: string;
};

const countryOptions: DropdownOption[] = Object.entries(Country).map(
  (item) => ({
    name: item[1].name,
    value: item[1].name,
  })
);

export const documentOptions: DropdownOption[] = [
  {
    name: 'Passport',
    value: 'Passport',
  },
  {
    name: 'ID',
    value: 'ID',
  },
  {
    name: 'License card',
    value: 'License card',
  },
];

export const ModalDocument = ({
  show,
  onChangeShow,
  className,
  onSave,
}: Props) => {
  const { t } = useTranslation();

  const initialState = {
    type: '',
    country: '',
    year: '',
    description: '',
  };

  const yearOptions = years.map((year) => ({
    name: year,
    value: year,
  }));

  const [documentData, setDocumentData] =
    useState<dataModalDocument>(initialState);

  useEffect(() => {
    if (show) {
      setDocumentData(initialState);
    }
  }, [show]);

  const handleTypeChange = (typeDocument: string) => {
    setDocumentData({
      ...documentData,
      type: typeDocument,
    });
  };

  const handleCountryChange = (countryDocument: string) => {
    setDocumentData({
      ...documentData,
      country: countryDocument,
    });
  };

  const handleYearChange = (yearDocument: string) => {
    setDocumentData({
      ...documentData,
      year: yearDocument,
    });
  };

  const handleInputChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setDocumentData({
      ...documentData,
      [target.name]: target.value,
    });
  };

  const handleSubmit = () => {
    if (documentData.type && documentData.country && documentData.year) {
      onSave(
        documentData.type,
        documentData.country,
        documentData.year,
        documentData.description
      );
    }
  };

  return (
    <ModalIPStyles
      className={className}
      show={show}
      testId="modal-device"
      onChangeShow={onChangeShow}
      header={{
        title: t('Add a blocked document'),
        iconName: 'PlusCircle',
      }}
      footer={{
        closeButtonLabel: t('Cancel'),
        submitButtonLabel: t('Save'),
        onSubmitButton: () => handleSubmit,
      }}
      hasPadding
    >
      <FlexContainer flexDirection="column" rowGap="3.2">
        <Dropdown
          testId="document-input-type"
          onChange={handleTypeChange}
          options={documentOptions}
          label={t('Document Type')}
        />
        <Dropdown
          testId="document-input-country"
          onChange={handleCountryChange}
          options={countryOptions}
          label={t('Country')}
        />
        <Dropdown
          testId="document-input-year"
          onChange={handleYearChange}
          options={yearOptions}
          label={t('Year')}
        />
        <Input
          testId="document-input-desc"
          name="description"
          label={t('Description (optional)')}
          onChange={handleInputChange}
        />
      </FlexContainer>
    </ModalIPStyles>
  );
};
