import { ButtonIcon, FlexContainer, Tooltip } from '@facephi/ui-react';
import { Link } from 'react-router-dom';
import { TableActionsProps } from './model';
import { StyledButtonIcon } from './Styles';
import { DEFAULT_LOCALE, translate } from '../../utils/translations';

export const TableActions = ({
  onSeeDetail,
  testId,
  className,
  dataTour,
  onClickError,
  hasError,
  locale = DEFAULT_LOCALE,
  detailRoute,
}: TableActionsProps) => {
  return (
    <FlexContainer columnGap="1" data-tour={dataTour}>
      <Tooltip label={translate('View detail', locale)} variant="secondary">
        {detailRoute ? (
          <Link to={detailRoute}>
            <ButtonIcon
              variant="text"
              iconName="Eye"
              className={className}
              testId={testId}
            />
          </Link>
        ) : (
          <ButtonIcon
            variant="text"
            iconName="Eye"
            onClick={onSeeDetail}
            className={className}
            testId={testId}
          />
        )}
      </Tooltip>

      {hasError && (
        <Tooltip label={translate('View error', locale)} variant="secondary">
          <StyledButtonIcon
            variant="text"
            iconName="Warning"
            onClick={onClickError}
            testId={testId}
          />
        </Tooltip>
      )}
    </FlexContainer>
  );
};
