import { memo } from 'react';
import { useAssets } from '../hooks';

type IProps = {
  id: string;
  alt: string;
  className?: string;
};

export const AssetImage = ({ id, alt, className }: IProps) => {
  const { urlAsset } = useAssets();

  return <img src={urlAsset(id)} alt={alt} className={className} />;
};

function checkProps(prevProps: IProps, nextProps: IProps) {
  if (prevProps.id !== nextProps.id) {
    return true;
  }

  return true;
}

export default memo(AssetImage, (prevProps, nextProps) =>
  checkProps(prevProps, nextProps)
);
