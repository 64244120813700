import { gql } from '@apollo/client';

export const updateOperations = gql`
  subscription operations(
    $status: [EntityStatus!]
    $fromTimestamp: String
    $limit: Int
    $text: String
    $family: [OperationType!]
  ) {
    operations: operations(
      parameters: {
        status: $status
        fromTimestamp: $fromTimestamp
        limit: $limit
        text: $text
        include: $family
      }
    ) {
      edges {
        node {
          operationId
          family: type
          customerId
          creationTime
          lastUpdateTime
          currentStep
          steps
          stepsFlow
          status
          results {
            type
            status
            reason
          }
          assets {
            type
            url
            contentType
          }
          deleted
        }
      }
    }
  }
` as any;

export const updateOperationDetails = gql`
  subscription operationDetails($id: String!) {
    operation: operationDetails(operationId: $id) {
      family: type
      currentStep
      operationId
      creationTime
      lastUpdateTime
      steps
      status
      results {
        type
        status
        reason
      }
      details {
        ... on AuthenticationDetails {
          assets {
            url
            contentType
            type
          }
          device {
            type
            model
            osVersion
            brand
            deviceId
          }
          coordinates {
            latitude
            longitude
          }
          facialAuthenticateResult {
            similarity
            authStatus
          }
          facialLivenessResult {
            diagnostic
            description
          }
          ocrData {
            personalInformation {
              name
              surname
              nationality
              documentNumber
              birthDate
              birthPlace
              address
              city
            }
            default
            source
            documentDataFields {
              key
              value
            }
            documentImages {
              type
              url
              contentType
            }
          }
        }
        ... on OnboardingDetails {
          coordinates {
            latitude
            longitude
          }
          facialAuthenticateResult {
            similarity
            authStatus
          }
          facialLivenessResult {
            diagnostic
            description
          }
          device {
            type
            model
            osVersion
            brand
            deviceId
          }
          ocrData {
            personalInformation {
              name
              surname
              nationality
              documentNumber
              birthDate
              birthPlace
              address
              city
            }
            source
            default
            documentDataFields {
              key
              value
            }
            documentImages {
              type
              url
              contentType
            }
          }
          securityData {
            verificationStatus
            scanStatus
            scanSource
            dataPositions
            documentValidation
            hologramValidation
            mrzCodeVerification
            featuresVerification
            signatureVerification
            documentType
            documentSubType
            country
            rejectReason
            microprintVerification
          }
          assets {
            url
            contentType
            type
          }
        }
      }
    }
  }
` as any;
