import { FlexContainer, Icon, Label, setColorTheme } from '@facephi/ui-react';
import styled from 'styled-components';

export const TimelineStyles = styled(FlexContainer)``;

export const TimelineStepStyles = styled.div<{
  isError?: boolean;
  isSuccess?: boolean;
}>`
  position: relative;
  border-left: 2px solid ${setColorTheme('grey200')};
  height: 100%;
  padding-left: 2.4rem;

  &:last-of-type {
    border-left: none;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-55%, -12%);
    width: 2.2rem;
    aspect-ratio: 1;
    border-radius: 100%;
    background-color: ${(props) =>
      props.isError
        ? setColorTheme('error100')
        : props.isSuccess
        ? setColorTheme('success100')
        : setColorTheme('grey200')};
  }
`;

export const TimelineSubtitle = styled(Label)`
  margin: 0.4rem 0 1rem;
`;

export const TimelineResourcesStyles = styled(FlexContainer)`
  img {
    max-height: 3.8rem;
    max-width: 6.9rem;
    border-radius: 0.8rem;
    object-fit: contain;
  }
`;

export const TimelineResourcesName = styled(Label)`
  color: ${setColorTheme('grey400')};
`;

export const IconResult = styled(Icon)`
  margin-left: -3.2rem;
  top: 0.1rem;
  position: absolute;
` as any;
