import { gql } from '@apollo/client';

export const getTimeLine: any = gql`
  query searchOnboardingEvents($parameters: AuditSearchParameters) {
    timeline: searchAuditEvents(parameters: $parameters) {
      events {
        ... on OnboardingAudit {
          eventId
          clientTimestamp
          metadata: payload {
            ... on AssetPayload {
              type
              url
              contentType
            }
            ... on OnboardingPayload {
              type
              screen
              event
              value
            }
            ... on OperationResult {
              type
              status
              reason
            }
            ... on FacialAuthenticateResult {
              type
              similarity
              authStatus
              source
            }
            ... on FacialLivenessResult {
              type
              diagnostic
              description
            }
            ... on SecurityInfoDataQuery {
              type
              succeed
              data
              mode
              source
            }
            ... on TeseoWallet {
              type
              walletId
              source
            }
            ... on TeseoCredential {
              type
              walletId
              credentialId
              credentialType
            }
          }
        }
      }
    }
  }
` as any;

export const getTimeLineAuthentication: any = gql`
  query searchOnboardingEvents($parameters: AuditSearchParameters) {
    timeline: searchAuditEvents(parameters: $parameters) {
      events {
        ... on AuthenticationAudit {
          eventId
          date: clientTimestamp
          metadata: payload {
            ... on AssetPayload {
              type
              url
              contentType
            }
            ... on AuthenticationPayload {
              type
              screen
              event
              value
            }
            ... on OperationResult {
              type
              status
              reason
            }
            ... on TeseoWallet {
              type
              walletId
              source
            }
            ... on TeseoCredential {
              type
              walletId
              credentialId
              credentialType
            }
            ... on FacialAuthenticateResult {
              type
              similarity
              authStatus
            }
            ... on FacialLivenessResult {
              type
              diagnostic
            }
          }
        }
      }
    }
  }
` as any;
