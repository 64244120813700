import React, { ReactNode } from 'react';
import { AppName } from '@backoffice/state/constants';
import { AsideNav, AsideNavProps } from '@facephi/inphinite-common';
import { ButtonIcon, FlexContainer, Label } from '@facephi/ui-react';
import { NavigateOptions, useNavigate } from 'react-router-dom';
import {
  LayoutContent,
  LayoutHeader,
  LayoutStyles,
  LayoutWrapper,
} from './Styles';
import { AsideNavBottom } from '../asideNav';
import { Header } from '../header';
import { PanelLayout } from '../settings/PanelLayout';

export type LayoutProps = AsideNavProps & {
  children: ReactNode;
  title?: string;
  goBackRoute?: string | [string, NavigateOptions];
  withHeader?: boolean;
  image?: string;
  leftPanel?: React.ReactNode;
  app?: AppName;
};

export const Layout = ({
  children,
  title,
  goBackRoute,
  withHeader = true,
  leftPanel,
  app,
  ...rest
}: LayoutProps) => {
  const navigate = useNavigate();

  return (
    <LayoutStyles>
      <AsideNav {...rest}>
        <AsideNavBottom app={app} />
      </AsideNav>
      {leftPanel && <PanelLayout />}
      <LayoutWrapper flexDirection="column">
        <LayoutHeader
          alignItems="flex-start"
          justifyContent={title ? 'space-between' : 'flex-end'}
          forwardedAs="header"
          columnGap="1"
        >
          {goBackRoute && (
            <FlexContainer alignItems="center" columnGap="2.1">
              <ButtonIcon
                iconName="CaretLeft"
                testId="button-back"
                onClick={() =>
                  typeof goBackRoute === 'string'
                    ? navigate(goBackRoute)
                    : navigate(...goBackRoute)
                }
              />
              <Label as="h1" fontSize="21" semibold>
                {title}
              </Label>
            </FlexContainer>
          )}
          {title && !goBackRoute && (
            <Label as="h1" fontSize="21" semibold>
              {title}
            </Label>
          )}
          {withHeader && <Header app={app} />}
        </LayoutHeader>
        <LayoutContent flexDirection="column">{children}</LayoutContent>
      </LayoutWrapper>
    </LayoutStyles>
  );
};
