import { useState } from 'react';
import { Roles } from '@facephi/inphinite-common';
import { RoleInfoBox } from '@facephi/inphinite-user-management';
import {
  CardBaseHeader,
  Dropdown,
  DropdownOption,
  Label,
} from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { PermissionApplicationContent, PermissionUserStyles } from './Styles';

export const PermissionUser = () => {
  const { t } = useTranslation();
  const [selectRole, setSelectRole] = useState<Roles>();

  const options: DropdownOption[] = [
    { name: t('Admin'), value: 'Admin' },
    { name: t('User'), value: 'User' },
  ];

  const handleChange = (value: Roles) => {
    setSelectRole(value);
  };

  return (
    <>
      <PermissionUserStyles>
        <CardBaseHeader>
          <Label fontSize="14" semibold>
            {t('Role')}
          </Label>
        </CardBaseHeader>

        <PermissionApplicationContent flexDirection="column" rowGap="2.4">
          <Dropdown
            placeholder={t('Select a role')}
            options={options}
            onChange={handleChange}
            size="L"
          />
          {selectRole && <RoleInfoBox role={selectRole} />}
        </PermissionApplicationContent>
      </PermissionUserStyles>
    </>
  );
};
