import { AppsIconsType } from '@backoffice/state/model';
import { IconWrapper, Label, setColorTheme } from '@facephi/ui-react';
import { CardUpgradeStyles } from './Styles';

type Props = AppsIconsType & {
  className?: string;
  disabled?: boolean;
};

export const CardUpgrade = ({
  label,
  color,
  iconName,
  className,
  disabled,
}: Props) => {
  return (
    <CardUpgradeStyles
      className={className}
      style={{
        borderLeft: `8px solid ${
          disabled ? setColorTheme('grey200') : setColorTheme(`${color}400`)
        }`,
      }}
      columnGap="1.2"
      alignItems="center"
      disabled={disabled}
    >
      <IconWrapper
        iconName={iconName}
        color={disabled ? 'grey' : color}
        size="M"
      />
      <Label fontSize="14" semibold>
        {label}
      </Label>
    </CardUpgradeStyles>
  );
};
