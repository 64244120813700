import { lazy, useEffect } from 'react';
import { BrowserNoValidate } from '@backoffice/components';
import { useAuth } from '@backoffice/providers';
import { AppName, RoutesName, browserList } from '@backoffice/state/constants';
import { PermissionsGate, SCOPES, useFlags } from '@facephi/inphinite-common';
import { compare } from 'compare-versions';
import dayjs from 'dayjs';
import { browserName, fullBrowserVersion } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import {
  BrowserRouter,
  Navigate,
  Routes as ReactRoutes,
  Route,
  generatePath,
} from 'react-router-dom';
import { ProtectedRoute } from './ProtectedRoute';

require('dayjs/locale/es');
require('dayjs/locale/en');

const DashboardPage = lazy(() => import('@backoffice/pages/Dashboard'));
const OperationsPage = lazy(() => import('@backoffice/pages/Operations'));
const AntifraudPage = lazy(() => import('@backoffice/pages/Antifraud'));
const Login = lazy(() => import('@backoffice/pages/Login'));
const SettingsPage = lazy(() => import('@backoffice/pages/Settings'));
const Page404 = lazy(() => import('@backoffice/pages/Error404'));
const Page500 = lazy(() => import('@backoffice/pages/Error500'));
const ReportingPage = lazy(() => import('@backoffice/pages/Reporting'));
const Video = lazy(() => import('@backoffice/pages/Video'));
const UXPage = lazy(() => import('@backoffice/pages/UX'));
const AIPage = lazy(() => import('@backoffice/pages/AI'));
const UpgradePage = lazy(() => import('@backoffice/pages/Upgrade'));
const ProfilePage = lazy(() => import('@backoffice/pages/Profile'));
const UserProfilePage = lazy(() => import('@backoffice/pages/UserPanel'));
const PermissionPage = lazy(() => import('@backoffice/pages/Permissions'));
const ManagementPage = lazy(() => import('@backoffice/pages/Management'));
const UserManagementPage = lazy(
  () => import('@backoffice/pages/UserManagement')
);
const Home = lazy(() => import('@backoffice/pages/Home'));
const PlanPage = lazy(() => import('@backoffice/pages/Upgrade'));
const VideoCallsPage = lazy(() => import('@backoffice/pages/VideoCalls'));
const AuditPage = lazy(() => import('@backoffice/pages/Audit'));
const BehaviourPage = lazy(() => import('@backoffice/pages/Behaviour'));
const CiamPage = lazy(() => import('@backoffice/pages/Ciam'));

export const Routes = () => {
  const { isAuthenticated } = useAuth();
  const { demo } = useFlags();
  const { i18n } = useTranslation();

  useEffect(() => {
    dayjs.locale(i18n.language?.split('-')[0]);
  }, [i18n.language]);

  const validateBrowser = () => {
    const currentBrowser = browserList.find(
      (item) => item.name === browserName
    );
    return (
      currentBrowser &&
      compare(fullBrowserVersion, currentBrowser.version, '>=')
    );
  };

  if (isAuthenticated === undefined) {
    return null;
  }

  return validateBrowser() ? (
    <BrowserRouter>
      <ReactRoutes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Home />} />
        <Route
          path={RoutesName.dashboard}
          element={
            <PermissionsGate scopes={[SCOPES.canAccessOperations]} redirect>
              <ProtectedRoute
                component={DashboardPage}
                app={AppName.operations}
              />
            </PermissionsGate>
          }
        />
        <Route
          path={`${RoutesName.operations}/*`}
          element={
            <PermissionsGate scopes={[SCOPES.canAccessOperations]}>
              <ProtectedRoute
                component={OperationsPage}
                app={AppName.operations}
              />
            </PermissionsGate>
          }
        />
        <Route
          path={RoutesName.reporting}
          element={
            <PermissionsGate scopes={[SCOPES.canViewStudio]} redirect>
              <ProtectedRoute
                component={ReportingPage}
                app={AppName.operations}
                flags={[demo]}
              />
            </PermissionsGate>
          }
        />
        <Route
          path={RoutesName.reporting}
          element={
            <ProtectedRoute
              component={ReportingPage}
              app={AppName.operations}
              flags={[demo]}
            />
          }
        />
        <Route
          path={`${RoutesName.ux}/*`}
          element={
            <PermissionsGate scopes={[SCOPES.canViewUX]} redirect>
              <ProtectedRoute component={UXPage} app={AppName.ux} />
            </PermissionsGate>
          }
        />
        <Route
          path={`${RoutesName.antifraud}/*`}
          element={
            <PermissionsGate scopes={[SCOPES.canViewAntifraud]}>
              <ProtectedRoute
                component={AntifraudPage}
                app={AppName.antiFraud}
              />
            </PermissionsGate>
          }
        />
        <Route
          path={`${RoutesName.ai}/*`}
          element={
            <PermissionsGate scopes={[SCOPES.canViewAI]} redirect>
              <ProtectedRoute component={AIPage} app={AppName.ai} />
            </PermissionsGate>
          }
        />
        <Route
          path={RoutesName.privacy}
          element={
            <ProtectedRoute component={UpgradePage} app={AppName.privacy} />
          }
        />
        <Route
          path={`${RoutesName.audit}/*`}
          element={
            <PermissionsGate scopes={[SCOPES.canViewAudit]} redirect>
              <ProtectedRoute component={AuditPage} app={AppName.audit} />
            </PermissionsGate>
          }
        />
        <Route
          path={RoutesName.videoId}
          element={<ProtectedRoute component={Video} />}
        />
        <Route
          path={`${RoutesName.settings}/*`}
          element={<ProtectedRoute component={SettingsPage} />}
        >
          <Route index element={<Navigate to={RoutesName.profile} />} />
          <Route
            path={RoutesName.profile.replace(RoutesName.settings, '')}
            element={<ProfilePage />}
          />
          <Route
            path={RoutesName.mainManagement.replace(RoutesName.settings, '')}
            element={
              <Navigate
                to={generatePath(RoutesName.management, { type: 'users' })}
              />
            }
          />
          <Route
            path={RoutesName.management.replace(RoutesName.settings, '')}
            element={
              <PermissionsGate scopes={[SCOPES.canViewUserManagment]} redirect>
                <ManagementPage />
              </PermissionsGate>
            }
          >
            <Route
              path={RoutesName.permissions.replace(RoutesName.management, '')}
              element={<PermissionPage />}
            />
          </Route>
          <Route
            path={RoutesName.myPlan.replace(RoutesName.settings, '')}
            element={<PlanPage />}
          />
          <Route path="*" element={<Navigate to={RoutesName.notFound} />} />
        </Route>
        <Route
          path={RoutesName.userManagement}
          element={
            <PermissionsGate scopes={[SCOPES.canViewUserManagment]} redirect>
              <ProtectedRoute
                component={UserManagementPage}
                app={AppName.userManagement}
              ></ProtectedRoute>
            </PermissionsGate>
          }
        ></Route>
        <Route
          path={RoutesName.userManagementProfile}
          element={
            <ProtectedRoute
              component={UserProfilePage}
              app={AppName.userManagement}
            ></ProtectedRoute>
          }
        />
        <Route
          path={RoutesName.videoCalls}
          element={
            <ProtectedRoute
              component={VideoCallsPage}
              app={AppName.videoAssistance}
            />
          }
        />
        <Route
          path={`${RoutesName.behaviour}/*`}
          element={
            <PermissionsGate scopes={[SCOPES.canViewBehaviour]} redirect>
              <ProtectedRoute
                component={BehaviourPage}
                app={AppName.behaviour}
              />
            </PermissionsGate>
          }
        />
        <Route
          path={`${RoutesName.ciam}/*`}
          element={
            <PermissionsGate scopes={[SCOPES.canViewCiam]} redirect>
              <ProtectedRoute component={CiamPage} app={AppName.ciam} />
            </PermissionsGate>
          }
        />
        <Route path={RoutesName.error} element={<Page500 />} />
        <Route path="*" element={<Page404 />} />
      </ReactRoutes>
    </BrowserRouter>
  ) : (
    <BrowserNoValidate />
  );
};
