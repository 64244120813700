import * as yup from 'yup';

const colorRegex = /^#(?:[0-9a-f]{3}){1,2}$/i;

export const changeThemeSchema = yup.object().shape({
  primary: yup.string().matches(colorRegex, 'Must be a hexadecimal color'),
  secondary: yup.string().matches(colorRegex, 'Must be a hexadecimal color'),
  logoAside: yup.mixed().nullable().notRequired(),
  favicon: yup.mixed().nullable().notRequired(),
  title: yup.string().notRequired(),
});
