import { ASIDENAV_WIDTH } from '@facephi/inphinite-common';
import { FlexContainer, OptionMenu, setColorTheme } from '@facephi/ui-react';
import styled from 'styled-components/macro';

export const AsideNavBottomPanel = styled(OptionMenu)<{
  active?: boolean;
  itemsLength: number;
}>`
  margin-left: calc(${ASIDENAV_WIDTH} + 3.4rem - 0.8rem);
  display: grid;
  grid-template-columns: repeat(
    ${(props) => (props.itemsLength < 4 ? props.itemsLength : 4)},
    7rem
  );
  grid-row-gap: 1.6rem;
  grid-column-gap: 2.4rem;
  background: ${setColorTheme('white')};
  border-radius: 0.8rem;
  padding: 3.6rem 2.4rem;
  box-shadow: 0 5px 10px 0 ${setColorTheme('grey100')};
  max-height: unset;

  li {
    cursor: pointer;
    height: 8rem;
  }
`;

export const AsideNavBottomButton = styled(FlexContainer)<{
  isActive?: boolean;
}>`
  div {
    min-width: 5.6rem;
    width: 5.6rem;
    height: 5.6rem;
    border-radius: 0.8rem;
    ${(props) => props.isActive && 'background-color: rgb(58, 65, 81)'}
  }
`;

export const AsideNavBottomStyles = styled.nav`
  border-top: 2px solid ${setColorTheme('grey500')};
  margin-top: auto;
  justify-content: center;
  display: flex;
  padding-top: 1.6rem;
`;
