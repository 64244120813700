import { ReactNode } from 'react';
import { Button, FlexContainer, Label, LogoPlatform } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import {
  ErrorStatusLabel,
  LayoutError,
  LayoutErrorAnimation,
  LayoutErrorContent,
  LayoutErrorTitle,
} from './Styles';

type Props = {
  animationData: Record<string, unknown>;
  blackLogo?: boolean;
  code: '404' | '500';
  title: string;
  message?: ReactNode;
  buttonText: string;
  buttonRoute?: string;
};

export const LayoutErrors = ({
  animationData,
  blackLogo,
  code,
  title,
  message,
  buttonText,
  buttonRoute,
}: Props) => {
  const { t } = useTranslation();

  return (
    <LayoutError>
      <LayoutErrorAnimation animation={animationData} />
      <LayoutErrorContent
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
      >
        <LogoPlatform black={blackLogo} />
        <FlexContainer flexDirection="column" alignItems="center">
          <ErrorStatusLabel fontSize="21" textAlign="center" semibold>
            {code}
          </ErrorStatusLabel>
          <LayoutErrorTitle
            fontSize="21"
            semibold
            textAlign="center"
            data-test="error-title"
            negative={code === '404'}
          >
            {t(`${title}`)}
          </LayoutErrorTitle>
          {message && message}
          {buttonRoute ? (
            <Button
              iconName="Flag"
              as="a"
              href={buttonRoute}
              size="L"
              color="primary"
            >
              {t(`${buttonText}`)}
            </Button>
          ) : (
            <Label fontSize="18">{t(`${buttonText}`)}</Label>
          )}
        </FlexContainer>
      </LayoutErrorContent>
    </LayoutError>
  );
};
