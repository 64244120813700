import { ButtonIcon, setColorTheme } from '@facephi/ui-react';
import styled from 'styled-components';
import { HelpInfoTooltip } from '../helpInfoTooltip';

export const TableActionsError = styled(HelpInfoTooltip)`
  box-shadow: 0 2px 14px 0 ${setColorTheme('grey100')};
  width: 31rem;
  padding: 1.6rem 2.1rem 0.8rem;
`;

export const StyledButtonIcon = styled(ButtonIcon)`
  color: ${setColorTheme('error400')};
`;
