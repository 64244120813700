import {
  FlexContainer,
  Label,
  Modal,
  OptionMenuItem,
  TabsHorizontal,
  setColorTheme,
} from '@facephi/ui-react';
import styled from 'styled-components/macro';
import { RoleInfoBox } from '../roleInfoBox';

export const AvatarImage = styled.img`
  width: 5.2rem;
  height: 5.2rem;
  border-radius: 5.2rem;
  position: relative;
`;

export const UserManagenentTableHeaderStyles = styled(FlexContainer)`
  padding: 1.2rem 4rem 1.6rem 2.2rem;
`;

export const UserManagenentTabs = styled(TabsHorizontal)`
  width: 100%;
`;

export const UserManagenentTabsWrapper = styled(FlexContainer)`
  height: 100%;
`;

export const ModalManagementNewUserStyles = styled(Modal)`
  width: 45rem;
`;

export const ButtonPermission = styled(Label)`
  color: ${setColorTheme('primary400')};
  text-decoration: 1px underline ${setColorTheme('primary400')};
  cursor: pointer;
`;

export const UserManagementFormWrapper = styled(FlexContainer)`
  position: relative;
  margin-top: 5rem;
  width: 100%;
  height: 100%;
`;

export const UserAvatarStyles = styled(AvatarImage)`
  width: 2rem;
  height: 2rem;
`;

export const UserTableCellStyles = styled(FlexContainer)`
  > img {
    margin-right: 1rem;
  }
`;

export const RoleInfoItem = styled(RoleInfoBox)`
  margin: 0 2rem;
`;

export const HelpInfoTooltipContent = styled(FlexContainer)`
  padding: 1.4rem 1.6rem 3.2rem;
  hr {
    margin: 0;
    width: 100%;
    height: 0;
    border: 1px solid ${setColorTheme('grey50')};
  }
`;

export const OptionMenuItemWrapper = styled(OptionMenuItem)<{
  active?: boolean;
  disabled?: boolean;
}>`
  padding: 0 0.8rem;
  background: ${(props) => (props.active ? setColorTheme('grey50') : '')};
` as any;

export const SpinnerAbsolutePlacement = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  bottom: 0;
  right: 0;
  background: ${setColorTheme('white')};
  border-radius: 0.8rem;
`;
