import { gql } from '@apollo/client';

export const updateReview = gql`
  mutation updateReview($user: PatchReview!, $reviewId: String!) {
    user: patchReview(review: $review, reviewId: $reviewId) {
      id
      sediaData
      debtData
      evidenceData
      observations
    }
  }
`;

export const createReview = gql`
  mutation createReview(
    $input: OperationDetailsReviewInput!
    $operationId: String!
  ) {
    createReview(input: $input, operationId: $operationId) {
      succeeded
      message
    }
  }
`;
