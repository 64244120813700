import MailPNG from '@backoffice/assets/img/modalMail.png';
import { Button, FlexContainer } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { ModalMailLabel, ModalMailStyles } from './Styles';

type Props = {
  show: boolean;
  onChangeShow(show: boolean): void;
  onCreate(): void;
  onResend(): void;
};

export const ModalMail = ({
  show,
  onChangeShow,
  onCreate,
  onResend,
}: Props) => {
  const { t } = useTranslation();

  return (
    <ModalMailStyles
      show={show}
      onChangeShow={onChangeShow}
      header={{
        title: t('Email sent'),
        iconName: 'Envelope',
      }}
      footer={{
        closeButtonLabel: t('Cancel'),
        submitButtonLabel: t('OK'),
        onSubmitButton: onCreate,
      }}
      hasPadding
    >
      <FlexContainer flexDirection="column" alignItems="center" rowGap="2.4">
        <img src={MailPNG} alt="email" />
        <ModalMailLabel fontSize="14">
          {t(
            'We have sent an email to reset your pasword. \n Check your inbox. \n If you have not received an email, please click on “Resend email”'
          )}
        </ModalMailLabel>
        <Button variant="text" iconName="ArrowClockwise" onClick={onResend}>
          {t('Resend email')}
        </Button>
      </FlexContainer>
    </ModalMailStyles>
  );
};
