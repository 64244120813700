import {
  FlexContainer,
  InphiniteThemeBaseColors,
  setColorTheme,
} from '@facephi/ui-react';
import styled from 'styled-components/macro';
import { RoleInfoBoxStyleProps } from './model';

export const RoleInfoBoxStyles = styled(FlexContainer)<
  RoleInfoBoxStyleProps & {
    backgroundColor: InphiniteThemeBaseColors;
  }
>`
  background-color: ${({ backgroundColor }) =>
    setColorTheme(`${backgroundColor}100`)};
  border-radius: 0.8rem;
  padding: ${(props) =>
    props.paddingBig ? '2.1rem 2.1rem 1.6rem' : '1.2rem 1.4rem 1.8rem'};

  p {
    transition: none;
  }
`;
