export enum CardReportingStatus {
  UPDATE = 'Updated today',
  PENDING = 'Pending',
  AVAILABLE = 'Available',
}

export type CardReportingStyleProps = {
  type:
    | 'BLACKLISTED'
    | 'STARTED'
    | 'ACCEPTED'
    | 'EXPIRED'
    | 'INPROCESS'
    | 'VALIDATE'
    | 'PENDENT'
    | 'REJECTED';
};

export type CardReportingProps = CardReportingStyleProps & {
  totalItems: string | number;
  newItems: string | number;
  testId?: string;
  className?: string;
  status: CardReportingStatus;
  dataTour?: string;
};
