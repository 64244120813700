import { useState } from 'react';

export function useForceUpdate() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setValue] = useState(0);
  const forceUpdate = () => setValue((value) => value + 1);

  return {
    forceUpdate,
  };
}
