import { FlexContainer, IconWrapper } from '@facephi/ui-react';
import styled from 'styled-components/macro';

export const CardKpiWrapper = styled(FlexContainer)`
  height: 100%;
`;

export const CardKpiIconContent = styled.div`
  margin-right: 3.4rem;
`;

export const CardKpiContent = styled(FlexContainer).attrs({
  flex: '1',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '1.8',
})``;

export const CardIcon = styled(IconWrapper)`
  opacity: 0.7;
  margin-right: 3.4rem;
` as any;
