import {
  CardBase,
  InphiniteThemeBaseColors,
  Label,
  setColorTheme,
} from '@facephi/ui-react';
import styled from 'styled-components';

export const CardUXStyle = styled(CardBase)<{
  backgroundColor: InphiniteThemeBaseColors;
}>`
  padding: 3rem;
  padding-bottom: 1.2rem;
  border-left: 8px solid
    ${({ backgroundColor }) => setColorTheme(`${backgroundColor}400`)};
`;

export const CardUXTitle = styled(Label)`
  margin: 3.6rem 0 1rem;
  padding-left: 0.8rem;
`;

export const CardUXSubtitle = styled(Label)`
  padding-left: 0.8rem;
  margin-bottom: auto;
  padding-left: 0.8rem;
`;
