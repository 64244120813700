import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { RoleDto } from '../state/model';
import { getRoles } from '../state/queries';

export function useRoles() {
  const { data } = useQuery<{ listRoles: { roles: RoleDto[] } }>(getRoles, {
    nextFetchPolicy: 'cache-first',
  });

  const roles = useMemo(() => (data ? data.listRoles.roles : []), [data]);

  return {
    roles,
  };
}
