/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useEffect, useState } from 'react';
import { useForceUpdate } from '@backoffice/hooks';
import { RoleDto, Tenant, UserDto } from '@backoffice/state/model';
import { CommonProvider, Roles } from '@facephi/inphinite-common';
import { useTimezone } from '@facephi/ui-react';
import { useKeycloak } from '@react-keycloak/web';
import axios from 'axios';

type IdTokenParsed = Keycloak.KeycloakTokenParsed & {
  tenant: {
    tenants: Tenant[];
  };
};

type TokenParsed = Keycloak.KeycloakTokenParsed & {
  iss: string;
  scope: string;
  tenant: {
    id: string;
    name: string;
    role: RoleDto;
  };
};

type IProps = {
  children: React.ReactNode;
};

type ContextProps = {
  isAuthenticated?: boolean;
  user?: UserDto;
  login(): void;
  logout(): void;
  handleRefreshToken(): Promise<string | undefined>;
  token: string | null | undefined;
  refreshToken: string | null | undefined;
  tenant: Tenant;
  changeToken(tenantId: string): void;
  saveDataUser(user: UserDto): void;
  role: Roles | null;
  tenants: Tenant[];
};

const Ctx = createContext<ContextProps>({
  login: () => {},
  logout: () => {},
  handleRefreshToken: () => new Promise((resolve) => resolve('')),
  token: null,
  refreshToken: null,
  tenant: {} as Tenant,
  changeToken: () => {},
  saveDataUser: () => {},
  role: null,
  tenants: [],
});

export const AuthProvider = ({ children }: IProps) => {
  const { keycloak, initialized } = useKeycloak();

  const { setTimezone } = useTimezone();
  const { forceUpdate } = useForceUpdate();

  const [isAuthenticated, setAuthentication] = useState<boolean | undefined>();
  const [user, setUser] = useState<UserDto | undefined>();

  const changeToken = async (tenantId: string) => {
    const params = new URLSearchParams();
    params.append('client_id', keycloak.clientId || '');
    params.append('grant_type', 'tenant_exchange');
    params.append('tenant_id', tenantId);
    params.append('scope', (keycloak.tokenParsed as TokenParsed)?.scope);
    params.append('access_token', keycloak.token || '');

    await axios.post(
      `${
        (keycloak.tokenParsed as TokenParsed)?.iss
      }/protocol/openid-connect/token`,
      params
    );
    const minValidity =
      (keycloak.tokenParsed?.exp || 0) - new Date().getTime() / 1000;
    await keycloak.updateToken(minValidity + 1);
    forceUpdate();
  };

  useEffect(() => {
    if (keycloak && initialized) {
      keycloak.onAuthRefreshError = () => {
        logout();
      };

      keycloak.onTokenExpired = () => {
        keycloak.updateToken(5);
      };

      setAuthentication(keycloak.authenticated);
    }
  }, [keycloak, initialized]);

  useEffect(() => {
    if (isAuthenticated) {
      keycloak.loadUserInfo().then(({ tenants, ...userData }: any) => {
        if (userData.timezone && userData.timezone !== '') {
          setTimezone(userData.timezone);
        }

        setUser({
          email: userData.email,
          name: userData.given_name,
          surname: userData.family_name,
          fullName: userData.name,
          id: userData.sub,
          language: userData.language,
        });
      });
    }
  }, [isAuthenticated]);

  const login = () => keycloak.login();

  const logout = () => keycloak.logout();

  const saveDataUser = (userData: UserDto) => {
    setUser((user) => ({ ...user, ...userData }));
  };

  const handleRefreshToken = (): Promise<string | undefined> =>
    new Promise((resolve, reject) => {
      keycloak
        .updateToken(5)
        .then(() => {
          resolve(keycloak.token);
        })
        .catch(() => {
          reject();
        });
    });

  const tenant = {
    id: (keycloak.tokenParsed as TokenParsed)?.tenant?.id || '',
    name: (keycloak.tokenParsed as TokenParsed)?.tenant?.name || '',
  };

  const role =
    ((keycloak.tokenParsed as TokenParsed)?.tenant?.role?.name as Roles) ||
    Roles.User;

  return (
    <Ctx.Provider
      value={{
        user,
        saveDataUser,
        isAuthenticated,
        logout,
        login,
        handleRefreshToken,
        token: keycloak.token,
        refreshToken: keycloak.refreshToken,
        changeToken,
        tenant,
        role,
        tenants: (keycloak.idTokenParsed as IdTokenParsed)?.tenant.tenants.sort(
          (a, b) => a.name.localeCompare(b.name)
        ),
      }}
    >
      <CommonProvider tenant={tenant} token={keycloak.token} role={role}>
        {children}
      </CommonProvider>
    </Ctx.Provider>
  );
};

export const useAuth = () => React.useContext(Ctx);
