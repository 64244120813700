import { StatusType } from '@facephi/ui-react';

export enum MimeType {
  jpeg = 'image/jpeg',
  jpg = 'image/jpg',
  png = 'image/png',
  mp4 = 'video/mp4',
  pdf = 'application/pdf',
}

export type Asset = {
  type: string;
  url: string;
  contentType: MimeType;
};

export type Coordinates = {
  latitude: number;
  longitude: number;
};

export type Onboarding = {
  lastUpdateTime: string;
  onboardingId: string;
  customerId: string;
  currentStep: string;
  steps: string[];
  finishStep: string;
  status: string;
  assets: Asset[];
  family: string;
};

export type PersonalInformation = {
  name: string;
  surname: string;
  nationality: string;
};

export type Security = {
  verificationStatus: string;
  scanStatus: string;
  scanSource: string;
  dataPositions: boolean;
  documentValidation: boolean;
  hologramValidation: boolean;
  mrzCodeVerification: boolean;
  featuresVerification: boolean;
  signatureVerification: boolean;
  documentType: string;
  documentSubType: string;
  country: string;
  rejectReason: string;
  microprintVerification: boolean;
};

export enum FacialAuthenticationStatus {
  none = 'NONE',
  negative = 'NEGATIVE',
  uncertain = 'UNCERTAIN',
  positive = 'POSITIVE',
  pose = 'NONE_BECAUSE_POSE_EXCEED',
  invalidExtractions = 'NONE_BECAUSE_INVALID_EXTRACTIONS',
}

export enum FacialLivenessDiagnostic {
  none = 'NONE',
  spoof = 'SPOOF',
  uncertain = 'UNCERTAIN',
  live = 'LIVE',
  badQuality = 'NONE_BECAUSE_BAD_QUALITY',
  faceToClose = 'NONE_BECAUSE_FACE_TO_CLOSE',
  faceNotFound = 'NONE_BECAUSE_FACE_NOT_FOUND',
  faceTooSmall = 'NONE_BECAUSE_FACE_TOO_SMALL',
  angleTooLarge = 'NONE_BECAUSE_ANGLE_TOO_LARGE',
  imageDataError = 'NONE_BECAUSE_IMAGE_DATA_ERROR',
  internalError = 'NONE_BECAUSE_INTERNAL_ERROR',
  imagePreProcess = 'NONE_BECAUSE_IMAGE_PREPROCESS_ERROR',
  tooManyFaces = 'NONE_BECAUSE_TOO_MANY_FACES',
  tooCloseBorder = 'NONE_BECAUSE_FACE_TOO_CLOSE_TO_BORDER',
  faceCroped = 'NONE_BECAUSE_FACE_CROPPED',
  licenseError = 'NONE_BECAUSE_LICENSE_ERROR',
}

export enum EntityStatus {
  started = 'STARTED',
  denied = 'DENIED',
  expired = 'EXPIRED',
  blacklisted = 'BLACKLISTED',
  succeeded = 'SUCCEEDED',
  cancelled = 'CANCELLED',
}

export const TimeLineStatus = [
  EntityStatus.denied,
  EntityStatus.expired,
  EntityStatus.blacklisted,
  EntityStatus.cancelled,
];

export enum OperationResultReason {
  selphidInternalError = 'SELPHID_INTERNAL_ERROR',
  selphidCancelByUser = 'SELPHID_CANCEL_BY_USER',
  selphidTimeout = 'SELPHID_TIMEOUT',
  selphiInternalError = 'SELPHI_INTERNAL_ERROR',
  selphiCancelByUser = 'SELPHI_CANCEL_BY_USER',
  selphiTimeout = 'SELPHI_TIMEOUT',
  phingersInternalError = 'PHINGERS_INTERNAL_ERROR',
  phingersCancelByUser = 'PHINGERS_CANCEL_BY_USER',
  phingersYimeout = 'PHINGERS_TIMEOUT',
  agoraInternalError = 'AGORA_INTERNAL_ERROR',
  agoraCancelByUser = 'AGORA_CANCEL_BY_USER',
  sdkMobileError = 'SDK_MOBILE_ERROR',
  documentValidationNotassed = 'DOCUMENT_VALIDATION_NOT_PASSED',
  documentValidationError = 'DOCUMENT_VALIDATION_ERROR',
  facialAuthenticationNotPassed = 'FACIAL_AUTHENTICATION_NOT_PASSED',
  facialLivenessNotPassed = 'FACIAL_LIVENESS_NOT_PASSED',
  facialAuthenticationError = 'FACIAL_AUTHENTICATION_ERROR',
  facialLivenessError = 'FACIAL_LIVENESS_ERROR',
}

export type DataFields = {
  key: string;
  value: string;
};

export const FINISH_STEP: string[] = ['FINISH', 'FINISHED'];

export enum TrackingFamily {
  AUTHENTICATION = 'AUTHENTICATION',
  ONBOARDING = 'ONBOARDING',
}

export type DeviceInformation = {
  type?: string;
  model: string;
  osVersion: string;
  brand: string;
  deviceId?: string;
};

export type OperationResult = {
  type: string;
  status: EntityStatus;
  reason: OperationResultReason;
};

export type OcrData = {
  documentImages: Asset[];
  documentDataFields: DataFields[];
  personalInformation?: PersonalInformation;
  source: string;
  default?: boolean;
};

export type Operation = {
  family?: TrackingFamily;
  operationId: string;
  lastUpdateTime: string;
  creationTime: string;
  customerId: string;
  steps: string[];
  status: StatusType;
  currentStep: string;
  stepsFlow: string[];
  coordinates?: Coordinates;
  assets: Asset[];
  facialAuthenticateResult?: {
    similarity: number;
    authStatus: FacialAuthenticationStatus;
  };
  facialLivenessResult?: {
    diagnostic: FacialLivenessDiagnostic;
    description: string;
  };
  security?: Security;
  ocrData: OcrData[];
  device?: DeviceInformation;
  deleted?: boolean;
  results: OperationResult[];
};

export type TimelineEvent = {
  type: TimeLineType;
  date: string;
  metadata: TimelineMetadata;
};

export enum TimeLineEventType {
  screenAccess = 'SCREEN_ACCESS',
  stateChange = 'STATE_CHANGE',
  recording = 'RECORDING',
  recordingFile = 'RECORDING_FILE',
  recordingError = 'RECORDING_ERROR',
  onboardingResult = 'ONBOARDING_RESULT',
  asset = 'ASSET',
  stepChange = 'STEP_CHANGE',
}

export enum TimeLineType {
  asset = 'ASSET',
  onboarding = 'ONBOARDING',
  authentication = 'AUTHENTICATION',
  operationResult = 'OPERATION_RESULT',
}

export type TimelineMetadata = {
  [key: string]: string;
};
