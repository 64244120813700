import {
  IconName,
  InphiniteThemeBaseColors,
  Label,
  Status,
} from '@facephi/ui-react';
import { CardReportingProps, CardReportingStatus } from './model';

import {
  CardReportingContent,
  CardReportingFooter,
  CardReportingIconWrapper,
  CardReportingStyles,
} from './Styles';

export const CardReporting = ({
  totalItems,
  type,
  newItems,
  status,
  testId,
  className,
  dataTour,
}: CardReportingProps) => {
  const reportingType = {
    BLACKLISTED: ['Blacklist', 'ClipboardText', 'grey'],
    STARTED: ['Started', 'Play', 'skyBlue'],
    ACCEPTED: ['Succeeded', 'CheckSquareOffset', 'green'],
    EXPIRED: ['Expired', 'Timer', 'pink'],
    INPROCESS: ['In process', 'PaperPlaneTilt', 'purple'],
    VALIDATE: ['Validations', 'Shield', 'blue'],
    PENDENT: ['Pending', 'HourglassLow', 'yellow'],
    REJECTED: ['Denied', 'FileX', 'error'],
  };

  const getTitle = (value: keyof typeof reportingType) => {
    return reportingType[value][0];
  };

  const getIcon = (value: keyof typeof reportingType) => {
    return reportingType[value][1] as IconName;
  };

  const getBackgroundColor = (value: keyof typeof reportingType) => {
    return reportingType[value][2];
  };

  return (
    <>
      <CardReportingStyles data-test={testId} className={className}>
        <CardReportingIconWrapper
          color={getBackgroundColor(type) as InphiniteThemeBaseColors}
          iconName={getIcon(type)}
          type={type}
        />
        <CardReportingContent flexDirection="column" rowGap="2">
          <Label fontSize="21" semibold data-tour={dataTour}>
            {getTitle(type)}
          </Label>
          <Label fontSize="14">Total: {totalItems}</Label>
          <Label fontSize="14">New: {newItems}</Label>
        </CardReportingContent>

        <CardReportingFooter
          alignItems="center"
          justifyContent="center"
          columnGap="0.6"
        >
          <Status
            status={
              status === CardReportingStatus.AVAILABLE
                ? 'ACTIVATED'
                : status === CardReportingStatus.UPDATE
                ? 'SUCCESSFUL'
                : 'PENDING'
            }
          />
          <Label fontSize="10" semibold>
            {status}
          </Label>
        </CardReportingFooter>
      </CardReportingStyles>
    </>
  );
};
