import {
  CardBase,
  FlexContainer,
  IconWrapper,
  setColorTheme,
} from '@facephi/ui-react';
import styled from 'styled-components';

import { CardReportingStyleProps } from './model';

export const CardReportingStyles = styled(CardBase)`
  position: relative;
  margin-top: 3rem;
`;

export const CardReportingIconWrapper = styled(
  IconWrapper
)<CardReportingStyleProps>`
  position: absolute;
  left: 4rem;
  top: -3rem;
  height: 6rem;
  width: 6rem;

  svg {
    min-width: 3.6rem;
    width: 3.6rem;
    height: 3.6rem;
  }
`;

export const CardReportingContent = styled(FlexContainer)`
  padding: 6rem 2rem 4rem 4rem;
`;

export const CardReportingFooter = styled(FlexContainer)`
  height: 6rem;
  border-top: 1px solid ${setColorTheme('grey200')};
`;
