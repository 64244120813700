import { Suspense } from 'react';
import { timeZoneVar } from '@backoffice/state/cache';
import { AnonymizeProvider, FlagsProvider } from '@facephi/inphinite-common';
import { PostHogProvider } from '@facephi/posthog-module';
import {
  InphiniteThemeProvider,
  Spinner,
  TimezoneProvider,
  ToastProvider,
} from '@facephi/ui-react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { createRoot } from 'react-dom/client';
import { SkeletonTheme } from 'react-loading-skeleton';
import keycloak from './keycloak';
import {
  ApiProvider,
  AuthProvider,
  TourProvider,
  TranslationProvider,
} from './providers';
import { Routes } from './routes/Routes';
import { GlobalStyles } from './styles/GlobalStyles';

const postHogApiKey: string = (window as any)._env_
  .REACT_APP_PUBLIC_POSTHOG_KEY;
const postHogOptions = {
  api_host: (window as any)._env_.REACT_APP_PUBLIC_POSTHOG_HOST,
};

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Suspense fallback={<Spinner />}>
    <InphiniteThemeProvider>
      <SkeletonTheme baseColor="#bfc5d4" highlightColor="#dbdee6">
        <TimezoneProvider setTimezone={timeZoneVar} timezone={timeZoneVar()}>
          <ToastProvider>
            <GlobalStyles />
            <TranslationProvider>
              <ReactKeycloakProvider authClient={keycloak}>
                <AuthProvider>
                  <ApiProvider>
                    <FlagsProvider>
                      <PostHogProvider
                        token={postHogApiKey}
                        options={postHogOptions}
                      >
                        <TourProvider>
                          <AnonymizeProvider>
                            <Routes />
                          </AnonymizeProvider>
                        </TourProvider>
                      </PostHogProvider>
                    </FlagsProvider>
                  </ApiProvider>
                </AuthProvider>
              </ReactKeycloakProvider>
            </TranslationProvider>
          </ToastProvider>
        </TimezoneProvider>
      </SkeletonTheme>
    </InphiniteThemeProvider>
  </Suspense>
);
