import { FlexContainer, setColorTheme } from '@facephi/ui-react';
import styled from 'styled-components/macro';

export const DetailHeaderStyles = styled(FlexContainer)`
  height: 6rem;
  padding: 0 3.2rem;
  border-radius: 0.8rem;
  box-shadow: 0 5px 10px 0 ${setColorTheme('grey100')};
  background-color: ${setColorTheme('white')};
`;
