import { Label } from '@facephi/ui-react';
import { RoleInfoLabelProps, RoleProperties } from './model';

export const RoleInfoLabel = ({
  role,
  fontSize,
  children,
  ...rest
}: RoleInfoLabelProps) => {
  return (
    <Label
      fontSize={fontSize}
      color={`${RoleProperties[role]?.color}400` || 'grey700'}
      {...rest}
    >
      {children}
    </Label>
  );
};
