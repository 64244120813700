import { useMemo, useState } from 'react';
import { useTour } from '@backoffice/providers';
import { AppName } from '@backoffice/state/constants';
import { useFlags } from '@facephi/inphinite-common';
import {
  FlexContainer,
  Icon,
  IconWrapper,
  Input,
  Label,
  TabPanel,
  TabsHorizontal,
} from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getLocaleLottie } from './lotties';
import { TutorialType, generalTour, tours } from './model/tour';
import {
  MODAL_TUTORIAL_WIDTH,
  ModalTutorialFooter,
  ModalTutorialHeader,
  ModalTutorialSection,
  ModalTutorialStyles,
  ModalTutorialWrapper,
} from './Styles';

type Props = {
  show?: boolean;
  onChangeShow(show: boolean): void;
  onGuide?(): void;
  app?: AppName;
  onCloseModal(): void;
};

export const ModalTutorial = ({
  show = false,
  onChangeShow,
  onGuide,
  app,
  onCloseModal,
}: Props) => {
  const { demo } = useFlags();
  const navigate = useNavigate();

  const { setIsOpen, setSteps } = useTour();
  const [activeTab, setActiveTab] = useState<string>('1');
  const { t, i18n } = useTranslation();
  const language = i18n.language.split('-')[0];

  const tutorialOptions: TutorialType[] | null = useMemo(() => {
    setActiveTab('1');
    if (app && tours[app]) {
      return (
        tours[app]?.({
          historyPush: navigate,
          t,
          getLottie: (text: string) => getLocaleLottie(text, language),
        }) || null
      );
    }
    return null;
  }, [app]);

  const handleTour = (steps: TutorialType['steps']) => {
    setSteps(steps);
    setIsOpen(true);
    onCloseModal && onCloseModal();
  };

  const handleWalktrhough = () => {
    onGuide?.();
    onCloseModal && onCloseModal();
  };

  const drawItemTutorial = (item: TutorialType, identifier: string) => (
    <ModalTutorialSection
      forwardedAs="button"
      alignItems="center"
      columnGap="1.6"
      disabled={!item.steps.length}
      onClick={() => handleTour(item.steps)}
      key={identifier}
    >
      <IconWrapper
        size="M"
        iconName={item.iconName}
        color={item.backgroundColor}
      />
      <FlexContainer
        flexDirection="column"
        rowGap="0.4"
        alignItems="flex-start"
      >
        <Label fontSize="12" semibold textAlign="left">
          {item.title}
        </Label>
        <Label fontSize="12" textAlign="left">
          {item.description}
        </Label>
      </FlexContainer>
    </ModalTutorialSection>
  );

  return (
    <ModalTutorialStyles
      testId="modal-tutorial"
      width={MODAL_TUTORIAL_WIDTH}
      onChangeShow={onChangeShow}
      show={show}
    >
      <>
        <ModalTutorialHeader
          justifyContent="space-between"
          alignItems="center"
          columnGap="1"
        >
          <Label fontSize="18" semibold>
            {t('How to')}
          </Label>
          {demo && <Input iconRight="MagnifyingGlass" />}
        </ModalTutorialHeader>
        <TabsHorizontal
          noPadding
          active={activeTab}
          onChangeActive={(activeTab: string) => setActiveTab(activeTab)}
        >
          <TabPanel id="1" name={t('General')}>
            <ModalTutorialWrapper flexDirection="column">
              {generalTour({
                t,
                historyPush: navigate,
                getLottie: (text) => getLocaleLottie(text, language),
                onCloseModal: () => setIsOpen(false),
              })
                ?.filter((item) => !item.demo || (item.demo && demo))
                .map((item, index) =>
                  drawItemTutorial(item, `general-${index}`)
                )}
            </ModalTutorialWrapper>
          </TabPanel>
          {app ? (
            <TabPanel name={t(app)} id="2">
              <ModalTutorialWrapper flexDirection="column">
                {tutorialOptions
                  ?.filter((item) => !item.demo || (item.demo && demo))
                  .map((item, index) => drawItemTutorial(item, `app-${index}`))}
              </ModalTutorialWrapper>
            </TabPanel>
          ) : (
            <></>
          )}
        </TabsHorizontal>
        <ModalTutorialFooter alignItems="center">
          <ModalTutorialSection
            forwardedAs="button"
            alignItems="center"
            columnGap="1.6"
            onClick={handleWalktrhough}
          >
            <IconWrapper size="M" iconName="Flag" color="purple" />
            <Label fontSize="12" semibold textAlign="left" color="purple400">
              {t('See the walkthrough again')}
            </Label>
            <Icon iconName="ArrowRight" size="24" color="grey400" />
          </ModalTutorialSection>
        </ModalTutorialFooter>
      </>
    </ModalTutorialStyles>
  );
};
