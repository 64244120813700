import * as yup from 'yup';
import {
  AddRoleDto,
  GeneralDto,
  ProfileDto,
  UserProfileFormDto,
} from './settings';

export const generalSchema: yup.SchemaOf<GeneralDto> = yup.object().shape({
  email: yup.string().required().email(),
  role: yup.number().required(),
  language: yup.string().required(),
  timezone: yup.string().required(),
});

export const profileSchema: yup.SchemaOf<ProfileDto> = yup.object().shape({
  avatar: yup.string(),
  name: yup.string(),
  surname: yup.string(),
  email: yup.string(),
  language: yup.string(),
  timezone: yup.string(),
});

export const userProfileSchema: yup.SchemaOf<UserProfileFormDto> = yup
  .object()
  .shape({
    name: yup.string().nullable(),
    surname: yup.string().nullable(),
    language: yup.string().nullable(),
    timezone: yup.string().nullable(),
    groups: yup.array(yup.string() as yup.StringSchema<string>).nullable(),
    role: yup.string().required(),
  });

export const addRoleSchema: yup.SchemaOf<AddRoleDto> = yup.object().shape({
  usersId: yup.array(yup.string().required()).min(1),
  role: yup.number().nullable().required(),
  nameGroup: yup.string(),
});

export const createUserSchema = yup.object().shape({
  username: yup.string().email().required(),
  groups: yup.array(yup.string()).default([]),
  roleId: yup.string().required('role is a required field'),
});

export const createGroupSchema = yup.object().shape({
  groupName: yup.string().required(),
  users: yup.array(yup.string()).default([]),
});
