import { InphiniteThemeBaseColors } from '@facephi/ui-react';

export enum RoutesName {
  antifraud = '/antifraud',
  rules = '/antifraud/rules',
  banned = '/antifraud/banned',
  bannedId = '/antifraud/banned/:id',
  video = '/video',
  videoId = '/video/:id',
  operations = '/operations',
  operationsId = '/operations/:id',
}

export const MAX_ITEMS_PAGE = 15;

export const REQUIRED_VALIDATION = 'This is a required field';
export const IP = 'ip';
export const IP_VALIDATION = 'The format of the IP field is invalid';
export const DESCRIPTION = 'description';
export const IMAGE_ID = 'media_id';
export const ID = 'id';

export const DOCUMENT_FACIAL_CAPTURE = 'DOCUMENT_FACIAL_CAPTURE';
export const CUSTOMER_PROVIDED_IMAGE = 'CUSTOMER_PROVIDED_IMAGE';
export const SELFIE = 'SELFIE';
export const NFC_PHOTO = 'NFC_PHOTO';
export const REFUSAL_REASON = 'Refusal Reason';

export const updatesIP = [
  '11/07/11 03:48:59',
  '15/04/19 16:05:15',
  '30/09/21 04:57:37',
  '29/12/10 22:02:06',
  '20/10/12 10:41:54',
  '06/05/15 01:34:24',
  '14/08/20 11:34:42',
];

export const ZOOM = {
  minScale: 0.25,
  minZoom: 25,
  defaultFlow: 25,
  defaultScale: 100,
  previewVideo: 1.5,
  maxScale: 100,
  defaultZoom: 100,
};

export const INTERNAL_PROVIDERS = {
  default: 'default',
  facephi: 'facephi',
  nfcMobile: 'nfc-mobile',
};

export const FULL_FRAME = 'FULLFRAME';

export enum OrderBy {
  lastUpdateTime = 'LAST_UPDATE_TIME',
  creationTime = 'CREATION_TIME',
}

export enum TagStatusType {
  STARTED = 'Started',
  LOGIN = 'Login',
  DISCLAIMER = 'Disclaimer',
  WELCOME = 'Welcome',
  MAIN = 'Main',
  SETTINGS = 'Settings',
  ADVANCED_SETTINGS = 'Advanced Settings',
  PREVIOUS_TIPS = 'Previous Tips',
  SELPHID_WIDGET = 'Selphid Widget',
  INTRO_CHIP = 'Intro Chip',
  CHIP_STATES = 'Chip States',
  INTRO_SELFIE = 'Intro Selfie',
  SELPHI_WIDGET = 'Selphi Widget',
  INTRO_RESULTS = 'Intro Results',
  RESULTS = 'Results',
  FINISHED = 'Finished',
  FINISH = 'Finish',
  START = 'Start',
  TERMS_WIDGET = 'Terms Widget',
  NFC_WIDGET = 'NFC',
  VIDEO_ID_WIDGET = 'Video ID',
  PHINGERS_WIDGET = 'Phingers',
  VIDEO_CALL_WIDGET = 'Video Call',
  FORM_WIDGET = 'Form widget',
  RENIEC_WIDGET = 'Reniec widget',
  VIDEO_CONTRACTING = 'Video Contracting',
  VOICE_ID_WIDGET = 'Voice Id Widget',
}

export const FlowSteps: {
  color: InphiniteThemeBaseColors;
  status: TagStatusType[];
}[] = [
  {
    color: 'blue',
    status: [
      TagStatusType.STARTED,
      TagStatusType.START,
      TagStatusType.LOGIN,
      TagStatusType.DISCLAIMER,
      TagStatusType.WELCOME,
      TagStatusType.MAIN,
      TagStatusType.SETTINGS,
      TagStatusType.ADVANCED_SETTINGS,
      TagStatusType.TERMS_WIDGET,
    ],
  },
  {
    color: 'yellow',
    status: [
      TagStatusType.PREVIOUS_TIPS,
      TagStatusType.SELPHID_WIDGET,
      TagStatusType.INTRO_CHIP,
      TagStatusType.CHIP_STATES,
      TagStatusType.RENIEC_WIDGET,
    ],
  },
  {
    color: 'purple',
    status: [
      TagStatusType.INTRO_SELFIE,
      TagStatusType.SELPHI_WIDGET,
      TagStatusType.FORM_WIDGET,
    ],
  },
  {
    color: 'skyBlue',
    status: [TagStatusType.NFC_WIDGET],
  },
  {
    color: 'green',
    status: [
      TagStatusType.INTRO_RESULTS,
      TagStatusType.RESULTS,
      TagStatusType.FINISHED,
      TagStatusType.FINISH,
      TagStatusType.PHINGERS_WIDGET,
    ],
  },
];
