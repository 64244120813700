import * as yup from 'yup';

export const reviewSchema = yup.object().shape({
  sediaData: yup.boolean().required().oneOf([true]),
  debtData: yup.boolean().required().oneOf([true]),
  evidenceData: yup.boolean().required().oneOf([true]),
  observations: yup.string().notRequired(),
});

export const rejectSchema = yup.object().shape({
  reasonId: yup.number().notRequired(),
  rejectNote: yup.string().required('Observations is a required field'),
});
