import { AssetImage } from '@facephi/inphinite-common';
import {
  Dropdown,
  FlexContainer,
  GridContainer,
  Modal,
} from '@facephi/ui-react';
import styled from 'styled-components/macro';
import { OperationsDataContent } from './operations';

export const BannedDataContent = styled(OperationsDataContent)``;

export const DropdownAntiFraud = styled(Dropdown)`
  width: 7.4rem;
` as any;

export const HeaderBanned = styled(FlexContainer)`
  margin-bottom: 6rem;
`;

export const HeaderRulesStyles = styled(FlexContainer)`
  margin: 2rem 1.6rem;
`;

export const RulesTabsWrapper = styled(FlexContainer)`
  height: 100%;
`;

export const ModalIPStyles = styled(Modal)`
  width: 52rem;
`;

export const ModalIPContent = styled(FlexContainer)`
  margin: 3rem 5rem;
  width: 100%;
`;

export const ModalFaceContent = styled(ModalIPContent)``;

export const ModalDeviceStyles = styled(ModalIPStyles)``;

export const ModalDeviceContent = styled(ModalIPContent)``;

export const ModalDocumentStyles = styled(ModalIPStyles)``;

export const ModalDocumentContent = styled(ModalIPContent)``;

export const ModalFieldStyles = styled(ModalIPStyles)``;

export const ModalFieldContent = styled(ModalIPContent)``;

export const TableFaceImg = styled(AssetImage)`
  width: 5rem;
  aspect-ratio: 1;
  object-fit: cover;
`;

export const TablePopImg = styled.img`
  width: 5rem;
  object-fit: cover;
`;

export const DashboardHeader = styled(FlexContainer)`
  margin-bottom: 2.4rem;

  .date-picker {
    width: 23rem;
  }
`;

export const DashboardGrid = styled(GridContainer)`
  flex: 1;

  > *:nth-child(1) {
    grid-column: 1;
    grid-row: 1;
  }
  > *:nth-child(2) {
    grid-column: 2;
    grid-row: 1;
  }
  > *:nth-child(3) {
    grid-column: 3;
    grid-row: 1;
  }
  > *:nth-child(4) {
    grid-column: 4;
    grid-row: 1/3;
  }
  > *:nth-child(5) {
    grid-column: 1/4;
    grid-row: 2/5;
  }
  > *:nth-child(6) {
    grid-column: 4;
    grid-row: 3/5;
  }
`;

export const CustomerIdTooltipContainer = styled.div`
  transform: scale(1);
  width: fit-content;
  max-width: 100%;
`;
