import styled from 'styled-components';

export const Device = styled.div`
  &.device {
    display: inline-block;
    position: relative;
    box-sizing: content-box !important;

    @media only screen and (max-height: 1024px) {
      transform: scale(0.9);
    }

    @media only screen and (max-height: 768px) {
      transform: scale(0.75);
    }

    @media only screen and (max-height: 640px) {
      transform: scale(0.6);
    }

    .screen {
      width: 100%;
      position: relative;
      height: 100%;
      z-index: 3;
      background: white;
      overflow: hidden;
      display: block;
      border-radius: 1px;
      box-shadow: 0 0 0 3px #111;
    }

    .top-bar,
    .bottom-bar {
      height: 3px;
      background: black;
      width: 100%;
      display: block;
    }

    .middle-bar {
      width: 3px;
      height: 4px;
      top: 0px;
      left: 90px;
      background: black;
      position: absolute;
    }

    &.iphone8 {
      width: 375px;
      height: 667px;
      padding: 105px 24px;
      background: #d9dbdc;
      border-radius: 56px;
      box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.2);

      &:before {
        width: calc(100% - 12px);
        height: calc(100% - 12px);
        position: absolute;
        top: 6px;
        content: '';
        left: 6px;
        border-radius: 50px;
        background: #f8f8f8;
        z-index: 1;
      }

      &:after {
        width: calc(100% - 16px);
        height: calc(100% - 16px);
        position: absolute;
        top: 8px;
        content: '';
        left: 8px;
        border-radius: 48px;
        box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.1),
          inset 0 0 6px 3px #ffffff;
        z-index: 2;
      }

      .home {
        border-radius: 100%;
        width: 68px;
        height: 68px;
        position: absolute;
        left: 50%;
        margin-left: -34px;
        bottom: 22px;
        z-index: 3;
        background: rgb(48, 50, 51);
        background: linear-gradient(
          135deg,
          rgba(48, 50, 51, 1) 0%,
          rgba(181, 183, 185, 1) 50%,
          rgba(240, 242, 242, 1) 69%,
          rgba(48, 50, 51, 1) 100%
        );

        &:before {
          background: #f8f8f8;
          position: absolute;
          content: '';
          border-radius: 100%;
          width: calc(100% - 8px);
          height: calc(100% - 8px);
          top: 4px;
          left: 4px;
        }
      }

      .top-bar {
        height: 14px;
        background: #bfbfc0;
        position: absolute;
        top: 68px;
        left: 0;
      }

      .bottom-bar {
        height: 14px;
        background: #bfbfc0;
        position: absolute;
        bottom: 68px;
        left: 0;
      }

      .sleep {
        position: absolute;
        top: 190px;
        right: -4px;
        width: 4px;
        height: 66px;
        border-radius: 0px 2px 2px 0px;
        background: #d9dbdc;
      }

      .volume {
        position: absolute;
        left: -4px;
        top: 188px;
        z-index: 0;
        height: 66px;
        width: 4px;
        border-radius: 2px 0px 0px 2px;
        background: #d9dbdc;

        &:before {
          position: absolute;
          left: 2px;
          top: -78px;
          height: 40px;
          width: 2px;
          border-radius: 2px 0px 0px 2px;
          background: inherit;
          content: '';
          display: block;
        }

        &:after {
          position: absolute;
          left: 0px;
          top: 82px;
          height: 66px;
          width: 4px;
          border-radius: 2px 0px 0px 2px;
          background: inherit;
          content: '';
          display: block;
        }
      }

      .camera {
        background: #3c3d3d;
        width: 12px;
        height: 12px;
        position: absolute;
        top: 24px;
        left: 50%;
        margin-left: -6px;
        border-radius: 100%;
        z-index: 3;
      }

      .sensor {
        background: #3c3d3d;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 49px;
        left: 134px;
        z-index: 3;
        border-radius: 100%;
      }

      .speaker {
        background: #292728;
        width: 70px;
        height: 6px;
        position: absolute;
        top: 54px;
        left: 50%;
        margin-left: -35px;
        border-radius: 6px;
        z-index: 3;
      }

      &.black {
        background: #464646;
        box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.7);

        &:before {
          background: #080808;
        }

        &:after {
          box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.1),
            inset 0 0 6px 3px #212121;
        }

        .top-bar,
        .bottom-bar {
          background: #212121;
        }

        .volume,
        .sleep {
          background: #464646;
        }

        .camera {
          background: #080808;
        }

        .home {
          background: rgb(8, 8, 8);
          background: linear-gradient(
            135deg,
            rgba(8, 8, 8, 1) 0%,
            rgba(70, 70, 70, 1) 50%,
            rgba(8, 8, 8, 1) 100%
          );

          &:before {
            background: #080808;
          }
        }
      }
    }

    &.nexus5 {
      padding: 50px 15px 50px 15px;
      width: 320px;
      height: 568px;
      background: #1e1e1e;
      border-radius: 20px;

      &:before {
        border-radius: 600px / 50px;
        background: inherit;
        content: '';
        top: 0;
        position: absolute;
        height: 103.1%;
        width: calc(100% - 26px);
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }

      .top-bar {
        width: calc(100% - 8px);
        height: calc(100% - 6px);
        position: absolute;
        top: 3px;
        left: 4px;
        border-radius: 20px;
        background: #181818;

        &:before {
          border-radius: 600px / 50px;
          background: inherit;
          content: '';
          top: 0;
          position: absolute;
          height: 103%;
          width: calc(100% - 26px);
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
      }

      .bottom-bar {
        display: none;
      }

      .sleep {
        width: 3px;
        position: absolute;
        left: -3px;
        top: 110px;
        height: 100px;
        background: inherit;
        border-radius: 2px 0px 0px 2px;
      }

      .volume {
        width: 3px;
        position: absolute;
        right: -3px;
        top: 70px;
        height: 45px;
        background: inherit;
        border-radius: 0px 2px 2px 0px;
      }

      .camera {
        background: #3c3d3d;
        width: 10px;
        height: 10px;
        position: absolute;
        top: 18px;
        left: 50%;
        z-index: 3;
        margin-left: -5px;
        border-radius: 100%;

        &:before {
          background: #3c3d3d;
          width: 6px;
          height: 6px;
          content: '';
          display: block;
          position: absolute;
          top: 2px;
          left: -100px;
          z-index: 3;
          border-radius: 100%;
        }
      }

      &.landscape {
        padding: 15px 50px 15px 50px;
        height: 320px;
        width: 568px;

        &:before {
          width: 103.1%;
          height: calc(100% - 26px);
          border-radius: 50px / 600px;
        }

        .top-bar {
          left: 3px;
          top: 4px;
          height: calc(100% - 8px);
          width: calc(100% - 6px);

          &:before {
            width: 103%;
            height: calc(100% - 26px);
            border-radius: 50px / 600px;
          }
        }

        .sleep {
          height: 3px;
          width: 100px;
          left: calc(100% - 210px);
          top: -3px;
          border-radius: 2px 2px 0px 0px;
        }

        .volume {
          height: 3px;
          width: 45px;
          right: 70px;
          top: 100%;
          border-radius: 0px 0px 2px 2px;
        }

        .camera {
          top: 50%;
          left: calc(100% - 18px);
          margin-left: 0;
          margin-top: -5px;

          &:before {
            top: -100px;
            left: 2px;
          }
        }
      }
    }

    &.iphone-x {
      width: 375px;
      height: 812px;
      padding: 26px;
      background: #fdfdfd;
      box-shadow: inset 0 0 11px 0 black;
      border-radius: 66px;

      .overflow {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 66px;
        overflow: hidden;
      }

      .shadow {
        border-radius: 100%;
        width: 90px;
        height: 90px;
        position: absolute;
        background: radial-gradient(
          ellipse at center,
          rgba(0, 0, 0, 0.6) 0%,
          rgba(255, 255, 255, 0) 60%
        );
      }

      .shadow--tl {
        top: -20px;
        left: -20px;
      }

      .shadow--tr {
        top: -20px;
        right: -20px;
      }

      .shadow--bl {
        bottom: -20px;
        left: -20px;
      }

      .shadow--br {
        bottom: -20px;
        right: -20px;
      }

      &:before {
        width: calc(100% - 10px);
        height: calc(100% - 10px);
        position: absolute;
        top: 5px;
        content: '';
        left: 5px;
        border-radius: 61px;
        background: black;
        z-index: 1;
      }

      .inner-shadow {
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        position: absolute;
        top: 10px;
        overflow: hidden;
        left: 10px;
        border-radius: 56px;
        box-shadow: inset 0 0 15px 0 rgba(white, 0.66);
        z-index: 1;

        &:before {
          box-shadow: inset 0 0 20px 0 #ffffff;
          width: 100%;
          height: 116%;
          position: absolute;
          top: -8%;
          content: '';
          left: 0;
          border-radius: 200px / 112px;
          z-index: 2;
        }
      }

      .screen {
        border-radius: 40px;
        box-shadow: none;
      }

      .top-bar,
      .bottom-bar {
        width: 100%;
        position: absolute;
        height: 8px;
        background: rgba(black, 0.1);
        left: 0;
      }

      .top-bar {
        top: 80px;
      }

      .bottom-bar {
        bottom: 80px;
      }

      .volume,
      .volume:before,
      .volume:after,
      .sleep {
        width: 3px;
        background: #b5b5b5;
        position: absolute;
      }

      .volume {
        left: -3px;
        top: 116px;
        height: 32px;

        &:before {
          height: 62px;
          top: 62px;
          content: '';
          left: 0;
        }

        &:after {
          height: 62px;
          top: 140px;
          content: '';
          left: 0;
        }
      }

      .sleep {
        height: 96px;
        top: 200px;
        right: -3px;
      }

      .camera {
        width: 6px;
        height: 6px;
        top: 9px;
        border-radius: 100%;
        position: absolute;
        left: 154px;
        background: #0d4d71;
      }

      .speaker {
        height: 6px;
        width: 60px;
        left: 50%;
        position: absolute;
        top: 9px;
        margin-left: -30px;
        background: #171818;
        border-radius: 6px;
      }

      .notch {
        position: absolute;
        width: 210px;
        height: 30px;
        top: 26px;
        left: 108px;
        z-index: 4;
        background: black;
        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 24px;

        &:before,
        &:after {
          content: '';
          height: 8px;
          position: absolute;
          top: 0;
          width: 8px;
        }

        &:after {
          background: radial-gradient(
            circle at bottom left,
            transparent 0,
            transparent 70%,
            black 70%,
            black 100%
          );
          left: -8px;
        }

        &:before {
          background: radial-gradient(
            circle at bottom right,
            transparent 0,
            transparent 70%,
            black 70%,
            black 100%
          );
          right: -8px;
        }
      }

      &.landscape {
        height: 375px;
        width: 812px;

        .top-bar,
        .bottom-bar {
          width: 8px;
          height: 100%;
          top: 0;
        }

        .top-bar {
          left: 80px;
        }

        .bottom-bar {
          right: 80px;
          bottom: auto;
          left: auto;
        }

        .volume,
        .volume:before,
        .volume:after,
        .sleep {
          height: 3px;
        }

        .inner-shadow:before {
          height: 100%;
          width: 116%;
          left: -8%;
          top: 0;
          border-radius: 112px / 200px;
        }

        .volume {
          bottom: -3px;
          top: auto;
          left: 116px;
          width: 32px;

          &:before {
            width: 62px;
            left: 62px;
            top: 0;
          }

          &:after {
            width: 62px;
            left: 140px;
            top: 0;
          }
        }

        .sleep {
          width: 96px;
          left: 200px;
          top: -3px;
          right: auto;
        }

        .camera {
          left: 9px;
          bottom: 154px;
          top: auto;
        }

        .speaker {
          width: 6px;
          height: 60px;
          left: 9px;
          top: 50%;
          margin-top: -30px;
          margin-left: 0;
        }

        .notch {
          height: 210px;
          width: 30px;
          left: 26px;
          bottom: 108px;
          top: auto;
          border-top-right-radius: 24px;
          border-bottom-right-radius: 24px;
          border-bottom-left-radius: 0;

          &:before,
          &:after {
            left: 0;
          }

          &:after {
            background: radial-gradient(
              circle at bottom right,
              transparent 0,
              transparent 70%,
              black 70%,
              black 100%
            );
            bottom: -8px;
            top: auto;
          }

          &:before {
            background: radial-gradient(
              circle at top right,
              transparent 0,
              transparent 70%,
              black 70%,
              black 100%
            );
            top: -8px;
          }
        }
      }
    }
  }
`;
