import React, { Ref, useEffect, useRef, useState } from 'react';
import { MimeType, WidgetItem, useLoadAsset } from '@facephi/inphinite-common';
import { FlexContainerProps, IconWrapper, Spinner } from '@facephi/ui-react';
import {
  ImageContentStyle,
  MagnifierStyles,
  OperationSourceStyles,
  PDFViewerStyles,
} from './Styles';
import { ZOOM } from '../../../state/constants';

type Props = {
  mimeType: MimeType;
  source?: string;
  resource?: string;
  title: string;
  zoom?: number;
  controls?: boolean;
  autoPlay?: boolean;
  className?: string;
  seconds?: number;
  zoomable?: boolean;
  preview?: boolean;
  onClick?: () => void;
  handleError?: (error: boolean) => void;
  hasAssetError?: boolean;
  flexDirectionError?: FlexContainerProps['flexDirection'];
};

export const OperationSource = React.forwardRef(
  (
    {
      mimeType = MimeType.png,
      source,
      resource,
      title,
      zoom = ZOOM.defaultZoom,
      controls,
      autoPlay,
      preview,
      className,
      seconds,
      zoomable,
      onClick,
      handleError,
      hasAssetError,
      flexDirectionError = 'row',
    }: Props,
    ref: Ref<HTMLDivElement>
  ) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const audioRef = useRef<HTMLAudioElement>(null);
    const [innerZoom, setInnerZoom] = useState<number>(zoom);
    const [asset, setAsset] = useState<string>();

    const { resourceUrl, loadingResource, hasError } = useLoadAsset(
      source,
      mimeType
    );

    useEffect(() => {
      handleError && handleError(hasError);
    }, [hasError]);

    useEffect(() => {
      const newAsset = source ? resourceUrl : resource;
      setAsset(newAsset);
    }, [source, resourceUrl, resource]);

    useEffect(() => {
      if (seconds && videoRef?.current) {
        videoRef.current.currentTime = seconds;
      }
    }, [seconds, videoRef]);

    useEffect(() => {
      setInnerZoom(zoom);
    }, [zoom]);

    useEffect(() => {
      if (seconds && audioRef?.current) {
        audioRef.current.currentTime = seconds;
      }
    }, [seconds, audioRef]);

    const ImageComponent = () => (
      <ImageContentStyle
        ref={ref}
        style={{ transform: `scale(${innerZoom / 100}` }}
      >
        {zoomable ? (
          <MagnifierStyles src={asset || ''} />
        ) : (
          <img className={className} src={asset} alt={title} />
        )}
      </ImageContentStyle>
    );

    const VideoComponent = () =>
      preview ? (
        <IconWrapper color="grey" iconName="VideoCamera" />
      ) : (
        <video
          ref={videoRef}
          className={className}
          controls={controls}
          autoPlay={autoPlay}
          height="100%"
          style={{ transform: `scale(${innerZoom / 100}` }}
        >
          <source src={asset} type={mimeType} />
        </video>
      );

    const PdfComponent = () =>
      preview ? (
        <IconWrapper color="grey" iconName="FilePdf" />
      ) : (
        <PDFViewerStyles>
          <iframe
            title={title || ''}
            src={`${asset}#toolbar=0&navpanes=0&scrollbar=0&statusbar=0&zoom=${innerZoom}`}
          />
        </PDFViewerStyles>
      );

    const AudioComponent = () =>
      preview ? (
        <IconWrapper color="grey" iconName="Microphone" />
      ) : (
        <audio
          ref={audioRef}
          className={className}
          controls={controls}
          autoPlay={autoPlay}
          style={{ transform: `scale(${innerZoom / 100}` }}
        >
          <source src={asset} type={mimeType} />
        </audio>
      );

    const typeDocument = {
      [MimeType.jpg]: ImageComponent,
      [MimeType.png]: ImageComponent,
      [MimeType.pdf]: PdfComponent,
      [MimeType.jpeg]: ImageComponent,
      [MimeType.mp4]: VideoComponent,
      [MimeType.wav]: AudioComponent,
    };

    const ComponentResource = typeDocument[mimeType];

    const handleClick = () => {
      onClick && onClick();
    };

    const isLoading = () => {
      return hasError || hasAssetError
        ? false
        : source
        ? loadingResource
        : !resource;
    };

    return isLoading() ? (
      <Spinner insideComponent size={preview ? 'S' : 'M'} />
    ) : ComponentResource ? (
      <OperationSourceStyles
        alignItems="center"
        justifyContent="center"
        onClick={handleClick}
      >
        {hasError || hasAssetError ? (
          <WidgetItem
            iconName="FileX"
            backgroundColor="error"
            size="L"
            direction={flexDirectionError}
            name="File error"
            labelSize="14"
            labelColor="error400"
          />
        ) : (
          <ComponentResource />
        )}
      </OperationSourceStyles>
    ) : null;
  }
);
