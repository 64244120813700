/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext } from 'react';

type IProps = {
  children: React.ReactNode;
};

type ContextProps = {
  demo: boolean;
};

const Ctx = createContext<ContextProps>({
  demo: true,
});

export const FlagsProvider = ({ children }: IProps) => {
  const demo = !!JSON.parse((window as any)._env_.REACT_APP_FLAG_DEMO || 0);

  return (
    <Ctx.Provider
      value={{
        demo,
      }}
    >
      {children}
    </Ctx.Provider>
  );
};

export const useFlags = () => React.useContext(Ctx);
