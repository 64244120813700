import { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useCommon } from '@facephi/inphinite-common';
import { GroupDto, UserListDto } from '../state/model';
import { getGroups, getUsersForGroup } from '../state/queries';

export function useGroups() {
  const {
    tenant: { id: tenantId },
  } = useCommon();

  const [groupId, setGroup] = useState<string>();

  const { data, loading: loadingGroups } = useQuery<{
    listGroups: { groups: GroupDto[] };
  }>(getGroups, {
    variables: {
      tenantId: tenantId,
    },
    nextFetchPolicy: 'cache-first',
  });

  const { data: rawUsers, loading: loadingUsers } = useQuery<{
    listUsers: { users: UserListDto[] };
  }>(getUsersForGroup, {
    variables: {
      tenantId: tenantId,
      groupId,
    },
    skip: !groupId,
    fetchPolicy: 'cache-and-network',
  });

  const groups = useMemo(() => (data ? data.listGroups.groups : []), [data]);

  const groupUsers = useMemo(() => {
    if (loadingUsers) {
      return null;
    }
    return rawUsers?.listUsers.users || [];
  }, [rawUsers, loadingUsers]);

  return {
    groups,
    loadingGroups,
    loadingUsers,
    setGroup,
    groupUsers,
  };
}
