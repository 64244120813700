import { Trans, useTranslation } from 'react-i18next';
import { RoleInfoBoxProps, RoleProperties } from './model';
import { RoleInfoLabel } from './RoleInfoLabel';
import { RoleInfoBoxStyles } from './Styles';
import { Roles } from '../../state/constants';

export const RoleInfoBox = ({
  role,
  smallLabel,
  className,
  paddingBig,
}: RoleInfoBoxProps) => {
  const { t } = useTranslation();

  const getColor = RoleProperties[role]?.color || 'grey';

  return (
    <>
      <RoleInfoBoxStyles
        className={className}
        forwardedAs="section"
        rowGap="1.2"
        flexDirection="column"
        paddingBig={paddingBig}
        backgroundColor={getColor}
      >
        <RoleInfoLabel fontSize={smallLabel ? '12' : '14'} role={role}>
          {role === Roles.Admin ? (
            <Trans
              i18nKey="<0>Admin</0> has super powers. <2/> This role has access to all the features of the platform. <2/> At least one is mandatory"
              t={t}
            >
              <strong>Admin</strong> has super powers.
              <br /> This role has access to all the features of the platfom.
              <br /> At least one is mandatory
            </Trans>
          ) : (
            <Trans
              i18nKey="<0>User</0> can access the entire platform but can not manage other users permission or information"
              t={t}
            >
              <strong>User</strong> can access the entire platform but can not
              manage other users permission or information.
            </Trans>
          )}
        </RoleInfoLabel>
      </RoleInfoBoxStyles>
    </>
  );
};
