import { useEffect, useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useCommon } from '@facephi/inphinite-common';
import {
  DropdownSearch,
  DropdownSearchOption,
  FlexContainer,
  Input,
  Spinner,
  useToast,
} from '@facephi/ui-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  ModalManagementNewUserStyles,
  SpinnerAbsolutePlacement,
} from './Styles';
import {
  GroupCreateDto,
  UserListDto,
  createGroupSchema,
} from '../../state/model';
import { createGroup } from '../../state/mutations';
import { getUsers } from '../../state/queries';

type Props = {
  show: boolean;
  onChangeShow(show: boolean): void;
};

export const ModalManagementNewGroup = ({ show, onChangeShow }: Props) => {
  const { t, i18n } = useTranslation();
  const {
    tenant: { id: tenantId },
  } = useCommon();
  const { toastManager } = useToast();

  const { data: rawUsers } = useQuery<{
    listUsers: { users: [{ node: UserListDto }] };
  }>(getUsers, {
    variables: { username: '' },
    nextFetchPolicy: 'cache-first',
  });

  const users: DropdownSearchOption[] = useMemo(
    () =>
      rawUsers?.listUsers.users?.reduce(
        (acc, { node }: { node: UserListDto }) => {
          acc.push({
            name: node.username,
            value: node.id,
            img: node.personalInformation.avatar || '/Avatar.png',
          });
          return acc;
        },
        [] as DropdownSearchOption[]
      ) || [],
    [rawUsers]
  );

  const {
    handleSubmit,
    register,
    control,
    setError,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(createGroupSchema),
  });

  useEffect(() => {
    if (!show) {
      setValue('groupName', undefined);
      setValue('users', undefined);
    }
  }, [show]);

  const [insertGroup, { loading }] = useMutation(createGroup, {
    onError: (error) => {
      setError('groupName', {
        type: 'manual',
        message: error.message,
      });
    },
    onCompleted: () => {
      onChangeShow(false);
      toastManager({
        type: 'success',
        message: t('Group successfully created'),
        duration: 3000,
        testId: 'save-profile',
      });
    },
  });

  const onSumbit: any = async (data: GroupCreateDto) => {
    await insertGroup({
      variables: {
        name: data.groupName,
        users: data.users,
        tenantId,
      },
      refetchQueries: ['searchUsers', 'searchGroups'],
    });
  };

  return (
    <ModalManagementNewUserStyles
      show={show}
      onChangeShow={onChangeShow}
      header={{
        title: t('Create a new group'),
        iconName: 'PlusCircle',
      }}
      footer={{
        closeButtonLabel: t('Cancel'),
        submitButtonLabel: t('Save'),
        onSubmitButton: () => handleSubmit(onSumbit),
      }}
      isForm
      hasPadding
    >
      <FlexContainer flexDirection="column" rowGap="2.4">
        {loading && (
          <SpinnerAbsolutePlacement>
            <Spinner />
          </SpinnerAbsolutePlacement>
        )}
        <Input
          type="text"
          label={t('Group name')}
          {...(register('groupName') as any)}
          errorLabel={
            errors.groupName?.message && t(errors.groupName.message as string)
          }
          testId="input-group-name"
        />
        <Controller
          name="users"
          control={control}
          render={({ field }) => (
            <DropdownSearch
              {...field}
              options={users}
              multiple
              label={t('Users')}
              placeholder={t('Select users')}
              locale={i18n.language}
              errorLabel={
                errors.users?.message && t(errors.users.message as string)
              }
            />
          )}
        />
      </FlexContainer>
    </ModalManagementNewUserStyles>
  );
};
