import React, { createContext, useState } from 'react';

type IProps = {
  children: React.ReactNode;
};

type ContextProps = {
  anonymized?: boolean;
  setAnonymized(value: boolean): void;
  anonymize(text?: string | number): string;
};

const Ctx = createContext<ContextProps>({
  anonymized: false,
  setAnonymized: () => {},
  anonymize: (text?: string | number) => String(text),
});

export const AnonymizeProvider = ({ children }: IProps) => {
  const [anonymized, setAnonymized] = useState<boolean>(false);

  const anonymize = (text?: string | number) => {
    if (!text || !anonymized) return text ? String(text) : '';
    const value = String(text);

    const long = value.length < 4 ? value.length - 1 : 3;
    return value.slice(0, -long).replace(/./g, '*') + value.slice(-long);
  };

  return (
    <Ctx.Provider
      value={{
        anonymized,
        setAnonymized,
        anonymize,
      }}
    >
      {children}
    </Ctx.Provider>
  );
};

export const useAnonymize = () => React.useContext(Ctx);
