import { useCallback } from 'react';
import {
  ButtonIcon,
  Chips,
  Label,
  OptionMenu,
  PositionX,
  Spinner,
} from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { EmptyUserBox, GroupBoxStyle, OptionContentStyle } from './Styles';
import { useGroups } from '../hooks';
import { UserListDto } from '../state/model';

type Props = {
  name: string;
  id: string;
};

export const GroupBox = ({ name, id }: Props) => {
  const { setGroup, groupUsers } = useGroups();
  const { t } = useTranslation();

  const handleClick = () => {
    setGroup(id);
  };

  const getName = (user: UserListDto) => {
    if (user.personalInformation.firstName) {
      return `${user.personalInformation.firstName} ${
        user.personalInformation.lastName || ''
      }`;
    }
    return user.username;
  };

  const getContent = useCallback(() => {
    if (!groupUsers) {
      return <Spinner insideComponent size="S" />;
    }
    if (!groupUsers.length) {
      return (
        <EmptyUserBox
          testId="empty-box"
          flexDirection="column"
          alignItems="center"
        >
          <Label fontSize="12" semibold>
            {t('Add user to this group.')}
          </Label>
          <Label fontSize="12">{t('There aren’t users in this group.')}</Label>
          <Label fontSize="12">
            {t('To add user, click on the button "Add new"')}
          </Label>
        </EmptyUserBox>
      );
    }

    return groupUsers?.map((user) => (
      <Chips
        key={user.id}
        imgSrc={user.personalInformation.avatar || '/Avatar.png'}
        label={getName(user)}
      />
    ));
  }, [groupUsers]);

  return (
    <GroupBoxStyle columnGap="0.8" alignItems="center">
      <Label fontSize="12" ellipsis>
        {name}
      </Label>
      <OptionMenu
        testId="group-box"
        positionX={PositionX.left}
        renderItem={
          <ButtonIcon
            iconName="Plus"
            variant="text"
            onClick={handleClick}
            size="S"
          />
        }
      >
        <OptionContentStyle
          testId="user-list"
          columnGap="0.6"
          rowGap="0.4"
          wrap="wrap"
        >
          {getContent()}
        </OptionContentStyle>
      </OptionMenu>
    </GroupBoxStyle>
  );
};
