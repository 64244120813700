import styled from 'styled-components/macro';

export const StackedContainer = styled.div`
  position: relative;
  .echarts-tooltip span:not(:last-of-type) {
    font-weight: 400 !important;
  }
`;

export const DonutChartLoading = styled.div`
  text-align: center;
  margin-top: 2.2rem;
  margin-bottom: 2.8rem;
`;

export const RingContainer = styled.div`
  position: relative;
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
