import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { LocalStorageEnum } from '@backoffice/state/constants';

type UseModalGuideType = {
  value: boolean;
  setValue: Dispatch<SetStateAction<boolean>>;
  setFalse: () => void;
  setTrue: () => void;
  toggle: (value: boolean) => void;
};

function useModalGuide(): UseModalGuideType {
  const [value, setValue] = useState(
    localStorage.getItem(LocalStorageEnum.previouslyVisited) ? false : true
  );

  const setTrue = () => setValue(true);
  const setFalse = () => setValue(false);
  const toggle = (value: boolean) => setValue(value);

  useEffect(() => {
    if (!localStorage.getItem(LocalStorageEnum.previouslyVisited)) {
      localStorage.setItem(LocalStorageEnum.previouslyVisited, 'true');
    }
  }, []);

  return { value, setValue, setTrue, setFalse, toggle };
}

export default useModalGuide;
