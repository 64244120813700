import {
  CardBase,
  CardBaseHeader,
  FlexContainer,
  Label,
  Modal,
  TextArea,
  setColorTheme,
} from '@facephi/ui-react';
import styled from 'styled-components/macro';
import { OperationsTabsContent } from '../../components';
import { CardBaseContent } from '../../components/CardDashboard/Styles';

export const ReviewStyles = styled(CardBase)`
  height: 100%;
  ${CardBaseContent} {
    background-color: ${setColorTheme('grey100')};
    padding: 3rem 0 0 3rem;
  }
`;

export const ReviewCard = styled(CardBase)<{ disabled?: boolean }>`
  flex-grow: 0.5;
  height: 100%;
`;

export const ReviewWrapper = styled(FlexContainer)`
  margin: 4.8rem 3.6rem 2.1rem;
  height: 100%;
`;

export const ReviewHeader = styled(CardBaseHeader)`
  position: relative;
`;

export const ReviewTextArea = styled(TextArea)`
  flex: 1;
  display: flex;
  flex-direction: column;

  > div[data-test='TextArea-container'] {
    height: 100%;
  }
` as any;

export const ReviewCardListElement = styled(FlexContainer)`
  border-bottom: 1px solid ${setColorTheme('grey100')};
  padding-bottom: 2rem;
`;

const Header = styled(CardBaseHeader);

export const ModalReviewStyles = styled(Modal)`
  max-width: 52rem;
  ${Header} {
    display: none;
  }
`;

export const ModalReviewContent = styled(FlexContainer)`
  padding: 0rem 9.4rem;
  img {
    width: 12.5rem;
    height: 12rem;
  }
`;

export const ModalReviewLabel = styled(Label)`
  white-space: pre-line;
  font-weight: 600;
`;

export const ModalManagementRejectStyles = styled(Modal)`
  width: 45rem;
`;

export const ModalManagementRejectWrapper = styled(FlexContainer)`
  padding: 0;
`;

export const SpinnerAbsolute = styled.div`
  z-index: 100;
`;

export const ReviewsDataContent = styled(OperationsTabsContent).attrs({
  rowGap: '2.4',
  columnGap: '2.4',
})`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  flex: 1;
`;
