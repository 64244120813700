import { FlexContainer, Icon, Label, LogoSquare } from '@facephi/ui-react';
import { AsideNavItem, AsideNavProps } from './model';
import {
  StyledMenuItem,
  StylesMenu,
  StylesMenuLink,
  StylesMenuNav,
  StylesSeparator,
} from './Styles';

export const AsideNav = ({
  menu,
  children,
  className,
  isPreviewMode,
}: AsideNavProps) => {
  return (
    <StylesMenu className={className} isPreviewMode={isPreviewMode}>
      <StylesMenuLink href="/">
        <LogoSquare />
      </StylesMenuLink>
      <StylesSeparator />
      <StylesMenuNav>
        <ul>
          {menu
            .filter(({ flags }) => !flags || !flags.some((el) => !el))
            .map((item: AsideNavItem) => (
              <li key={item.id} data-test={item.testId}>
                <StyledMenuItem to={item.link}>
                  <FlexContainer
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Icon iconName={item.icon} size="24" color="white" />
                  </FlexContainer>
                  <Label negative fontSize="10" textAlign="center">
                    {item.label}
                  </Label>
                </StyledMenuItem>
              </li>
            ))}
        </ul>
      </StylesMenuNav>
      {children && children}
    </StylesMenu>
  );
};
