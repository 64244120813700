import { useEffect, useState } from 'react';
import {
  Button,
  ButtonIcon,
  CardBaseFooter,
  CardBaseHeader,
  FlexContainer,
  Label,
} from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { QueueDataType } from './model';
import { QueueIdContent, QueueIdStyles, QueueIdTextArea } from './Styles';

type Props = {
  innerInfo: QueueDataType;
  onAttend?(): void;
  show?: boolean;
  onChangeShow(show: boolean): void;
};

export const QueueId = ({
  onAttend,
  show = false,
  onChangeShow,
  innerInfo,
}: Props) => {
  const { t } = useTranslation();
  const [innerShow, setInnerShow] = useState<boolean>(show);

  useEffect(() => {
    setInnerShow(show);
  }, [show]);

  useEffect(() => {
    onChangeShow && onChangeShow(innerShow);
  }, [innerShow]);

  const onCloseModal = () => {
    setInnerShow(false);
  };

  const handleClick = () => {
    onAttend && onAttend();
  };

  return (
    <QueueIdStyles data-test="call-id" innerShow={innerShow}>
      <CardBaseHeader>
        <Label as="h3" fontSize="14" semibold noWrap>
          {t('Call info')}
        </Label>
        <ButtonIcon
          iconName="ArrowRight"
          variant="text"
          size="L"
          onClick={onCloseModal}
        />
      </CardBaseHeader>
      <QueueIdContent flexDirection="column" rowGap="4" flex="1">
        <Label fontSize="18" semibold textAlign="center">
          {innerInfo.id}
        </Label>
        <FlexContainer as="ul" flexDirection="column" rowGap="2.4">
          <FlexContainer as="li" justifyContent="space-between">
            <Label fontSize="14" semibold noWrap>
              {t('Name')}:
            </Label>
            <Label fontSize="14" textAlign="right">
              {' '}
              {innerInfo.name}
            </Label>
          </FlexContainer>
        </FlexContainer>
        <QueueIdTextArea label={t('Add note here')} rows={10}></QueueIdTextArea>
      </QueueIdContent>
      <CardBaseFooter columnGap="2.8" justifyContent="flex-end" hasPadding>
        <Button
          iconName="Skull"
          color="error"
          variant="text"
          onClick={onCloseModal}
        >
          {t('Reject')}
        </Button>
        <Button onClick={handleClick}>{t('Attend the request')}</Button>
      </CardBaseFooter>
    </QueueIdStyles>
  );
};
