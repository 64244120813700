import { FlexContainer } from '@facephi/ui-react';
import styled from 'styled-components';

export const TableOptionsStyles = styled(FlexContainer)`
  padding-bottom: 2.7rem;

  > div:first-child {
    margin-right: auto;
    display: flex;
    grid-gap: 1rem;
  }

  > div:last-child {
    margin-left: auto;
    display: flex;
    grid-gap: 1.5rem;
  }
`;
