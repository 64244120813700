import en from '../assets/locales/en.json';
import es from '../assets/locales/es.json';
import pt from '../assets/locales/pt.json';

export const DEFAULT_LOCALE = 'en';

type ILocale = { [key in string]: string };

const Locales: { [key in string]: ILocale } = {
  en: en,
  es: es,
  pt: pt,
};

export const translate = (text: string, locale = 'en') => {
  const lng = locale.split('-')[0];

  const locales: ILocale = Locales[lng] ? Locales[lng] : en;

  return locales[text] || text;
};
