import {
  CardBase,
  FlexContainer,
  Label,
  LottieAnimation,
  Modal,
  ModalLateral,
  setColorTheme,
} from '@facephi/ui-react';
import styled from 'styled-components/macro';
import { ModalGuideElementStyles } from './ModalGuide';

export const MODAL_TUTORIAL_WIDTH = 41.2;
const PADDING_LEFT = '3.2rem';

export const ModalTutorialStyles = styled(ModalLateral)`
  background-color: ${setColorTheme('white')};
  box-shadow: 0 5px 10px 0 ${setColorTheme('grey100')};
  border-radius: 0.8rem 0 0 0.8rem;
  padding-top: 2.1rem;
  z-index: 9999;
`;

export const ModalTutorialHeader = styled(FlexContainer)`
  padding-bottom: 3.2rem;
  padding-left: ${PADDING_LEFT};
  padding-right: 2.2rem;
`;

export const ModalTutorialSection = styled(FlexContainer)`
  padding: 2.1rem ${PADDING_LEFT};
  border-bottom: 1px solid ${setColorTheme('grey200')};
  position: relative;
  margin: 0;
  &:first-of-type {
    border-top: 1px solid ${setColorTheme('grey200')};
  }

  &:before {
    content: '';
    position: absolute;
    left: -0.6rem;
    height: 100%;
    width: 0.6rem;
    border-radius: 0 0.8rem 0.8rem 0;
    background-color: ${setColorTheme('grey300')};
    transition: left 0.2s ease-in-out;
  }
  &:hover:not(:disabled) {
    &:before {
      left: 0rem;
    }
  }

  &:disabled {
    cursor: default;
  }
`;

export const ModalTutorialWrapper = styled(FlexContainer)`
  flex: 1;
  overflow-y: auto;
`;

export const ModalTutorialFooter = styled(FlexContainer)`
  height: 8.1rem;
  border-top: 1px solid ${setColorTheme('grey200')};
  padding: 0 ${PADDING_LEFT};

  ${ModalTutorialSection} {
    width: 100%;
    padding: 0;
    border: none;

    &:before {
      content: unset;
    }
    > svg {
      margin-left: auto;
    }
  }
`;

export const ModalGuideStyles = styled(Modal)`
  max-height: 90vh;
  overflow: hidden;
`;

export const ModalGuidePanelLeft = styled(FlexContainer)`
  width: 30rem;
  background-color: ${setColorTheme('grey50')};
`;

export const ModalGuidePanelLeftHeader = styled(FlexContainer)`
  padding: 3.6rem 3.2rem;
`;

export const ModalGuideList = styled(FlexContainer)`
  flex: 1;
  overflow-y: auto;
`;

export const ModalGuideElement = styled(FlexContainer)<ModalGuideElementStyles>`
  border-top: 1px solid ${setColorTheme('grey100')};
  flex: 1;
  padding-left: 3rem;
  cursor: pointer;
  min-height: 6rem;
  max-height: 6rem;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    right: ${(props) => (props.$isActive ? '0' : '-0.6rem')};
    height: 100%;
    width: 0.6rem;
    border-radius: 0.8rem 0 0 0.8rem;
    background-color: ${setColorTheme('yellow400')};
    transition: right 0.2s ease-in-out;
  }
`;

export const ModalGuidePanelRightStyles = styled(FlexContainer)`
  flex: 1;
  position: relative;
  overflow-y: auto;
`;

export const ModalGuidePanelRightContent = styled(FlexContainer)`
  padding: 3.6rem;
`;

export const ModalGuidePanelRightActions = styled(FlexContainer)`
  padding-top: 1.2rem;
`;

export const ModalGuideButtonClose = styled(FlexContainer)`
  background-color: ${setColorTheme('grey300')};
  height: 4rem;
  width: 4rem;
  position: absolute;
  top: 1.6rem;
  right: 1.6rem;
  border-radius: 100%;
  z-index: 99;
`;

export const CardTour = styled(CardBase)`
  box-shadow: none;
`;

export const ContentTour = styled.div`
  padding-left: 2.2rem;
  padding-right: 2.2rem;
  padding-top: 2.4rem;
`;

export const StepTour = styled(Label)`
  margin-bottom: 2.2rem;
`;

export const LottieContent = styled(LottieAnimation)`
  margin-bottom: 1.6rem;
`;

export const LottieStyle = styled(LottieAnimation)`
  min-width: 55rem;
  height: 40rem;
`;
