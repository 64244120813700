export enum RoutesName {
  userManagementProfile = '/user-management/:id/profile',
  newPermissions = '/settings/management/permissions',
  permissions = '/settings/management/:type/:id/permissions',
}

export enum Roles {
  Admin = 'ADMIN',
  User = 'USER',
  UserManagement = 'USER_MANAGEMENT',
}
