/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useState } from 'react';

import { Tenant, Roles, Operation } from '../state/model';

type IProps = {
  children: React.ReactNode;
  tenant: Tenant;
  token: string | null | undefined;
  role: Roles;
};

type ContextProps = {
  tenant: Tenant;
  token: string | null | undefined;
  role: Roles;
  operation: Operation | null;
  setOperation(operation: Operation): void;
  resetOperation(): void;
};

const Ctx = createContext<ContextProps>({
  tenant: {} as Tenant,
  token: null,
  role: Roles.User,
  operation: null,
  setOperation: () => {},
  resetOperation: () => {},
});

export const CommonProvider = ({ children, tenant, token, role }: IProps) => {
  const [operation, setOperation] = useState<Operation | null>(null);

  const resetOperation = () => setOperation(null);

  return (
    <Ctx.Provider
      value={{
        tenant,
        token,
        role,
        operation,
        setOperation,
        resetOperation,
      }}
    >
      {children}
    </Ctx.Provider>
  );
};

export const useCommon = () => React.useContext(Ctx);
