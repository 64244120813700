import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export function timeFromNow(date: string | Date) {
  return dayjs(date).fromNow();
}

// format
export function formatDate(
  date: Date | string,
  format = 'DD/MM/YYYY HH:mm:ss'
) {
  return dayjs(date).format(format);
}
