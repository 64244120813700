import { useEffect, useState } from 'react';
import { WebsocketProps } from '@backoffice/state/model';
import useWebSocket, { ReadyState } from 'react-use-websocket';

type IProps = WebsocketProps & {
  email?: string;
  role: string;
  tenantId: string;
};

export function useWS({ websocketUrl, token, email, role, tenantId }: IProps) {
  const [errorSocket, setErrorSocket] = useState<boolean>(false);

  const { sendMessage, lastMessage, readyState } = useWebSocket(
    `${websocketUrl}`,
    {
      onError: () => {
        setErrorSocket(true);
      },
    }
  );

  const getWaitingCalls = () => {
    sendMessage(
      JSON.stringify({
        subject: 'WAITING_CALLS',
        user: {
          email,
          role: [role],
          tenantId: [tenantId],
          token: '',
        },
      })
    );
  };

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      getWaitingCalls();
    }
  }, [readyState]);

  return {
    sendMessage,
    lastMessage,
    errorSocket,
    readyState,
    getWaitingCalls,
  };
}
