import { InMemoryCache, makeVar } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import { DEFAULT_TIMEZONE } from '@backoffice/state/constants';

export const flowIdVar = makeVar<string>('');
export const timeZoneVar = makeVar<string>(DEFAULT_TIMEZONE);

export const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        searchOperations: relayStylePagination(['parameters']),
        getFlows: relayStylePagination(['parameters']),
        searchUsers: relayStylePagination(['parameters']),
      },
    },
  },
  possibleTypes: {
    Payload: [
      'AuthenticationPayload',
      'AssetPayload',
      'OnboardingDetails',
      'AuthenticationDetails',
      'AuthenticationAudit',
      'OnboardingAudit',
      'OnboardingPayload',
      'OperationResult',
      'FacialLivenessResult',
      'FacialAuthenticateResult',
    ],
  },
});

export const usernameSearch = makeVar<string>('');
