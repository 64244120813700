import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useLocales } from '@facephi/inphinite-common';
import {
  Button,
  CardBaseFooter,
  CardBaseHeader,
  Dropdown,
  DropdownOption,
  DropdownSearchOption,
  FlexContainer,
  useTimezone,
  useToast,
} from '@facephi/ui-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  FormControlDropdown,
  FormControlDropdownSearch,
  FormProfileInput,
  UserPanelAvatar,
  UserPanelCard,
  UserPanelCardContent,
  UserPanelProfile,
  UserPanelStyles,
} from '../components';
import { useGroups, useRoles } from '../hooks';
import {
  GroupDto,
  UserProfileDto,
  UserProfileFormDto,
  userProfileSchema,
} from '../state/model';
import { updateUser } from '../state/mutations';
import { getUser } from '../state/queries';

export const UserPanelPage = () => {
  const { t, i18n } = useTranslation();
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const { id: userId } = useParams<{ id: string }>();

  const { toastManager } = useToast();
  const { groups } = useGroups();
  const { roles: rawRoles } = useRoles();
  const { locales: rawLocales } = useLocales();
  const { options: rawTimezones } = useTimezone();

  const optionsRoles: DropdownOption[] = useMemo(
    () =>
      rawRoles
        ? rawRoles.map((item: GroupDto) => ({
            name: t(item.name),
            value: item.id,
          }))
        : [],
    [rawRoles]
  );

  const optionsGroups: DropdownOption[] = useMemo(
    () =>
      groups
        ? groups.map((item: GroupDto) => ({
            name: item.name,
            value: item.id,
          }))
        : [],
    [groups]
  );

  const timezones: DropdownSearchOption[] = useMemo(
    () =>
      rawTimezones.map((key: string) => ({
        name: key,
        value: key,
      })),
    [rawTimezones]
  );

  const locales: DropdownOption[] = useMemo(
    () =>
      rawLocales.map((item: string) => ({
        name: t(item),
        value: item,
      })),
    [rawLocales]
  );

  const [saveUser, { loading }] = useMutation(updateUser, {
    refetchQueries: ['searchUsers', 'getUser'],
    onCompleted: () => {
      setCanEdit(!canEdit);
      toastManager({
        type: 'success',
        message: t('Updated user data'),
        duration: 3000,
        testId: 'save-profile',
      });
    },
  });
  const { data } = useQuery<{ user: UserProfileDto }>(getUser, {
    variables: { userId: userId },
    fetchPolicy: 'cache-and-network',
  });

  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors },
  } = useForm<FieldValues>({
    resolver: yupResolver(userProfileSchema),
  });

  useEffect(() => {
    if (data?.user) {
      setValue('name', data.user.personalInformation.firstName);
      setValue('surname', data.user.personalInformation.lastName);
      setValue('language', data.user.userPreferences.language);
      setValue('timezone', data.user.userPreferences.timezone);
      setValue(
        'groups',
        data.user.groups?.map(({ id }: GroupDto) => id)
      );
      setValue('role', data.user.role.id);
    }
  }, [data?.user]);

  const onSumbit = async (data: UserProfileFormDto) => {
    saveUser({
      variables: {
        userId,
        user: {
          roleId: data.role,
          personalInformation: {
            firstName: data.name,
            lastName: data.surname,
          },
          userPreferences: {
            language: data.language,
            timezone: data.timezone,
          },
          groups: data.groups,
        },
      },
    });
  };

  return (
    <UserPanelStyles onSubmit={handleSubmit(onSumbit)}>
      <UserPanelCard flex="1">
        <CardBaseHeader>
          <UserPanelAvatar
            alt="avatar"
            src={
              data?.user.personalInformation.avatar
                ? data?.user.personalInformation.avatar
                : '/Avatar.png'
            }
          />
        </CardBaseHeader>
        <UserPanelCardContent flexDirection="column" flex="1">
          <UserPanelProfile title={t('Personal information')}>
            <FlexContainer columnGap="4.8">
              <FormProfileInput
                type="text"
                {...register('name')}
                disabled={!canEdit}
                label={t('Name')}
                testId="input-name"
                errorLabel={
                  errors.name?.message && t(errors.name.message as string)
                }
              />
              <FormProfileInput
                type="text"
                {...register('surname')}
                disabled={!canEdit}
                label={t('Surname')}
                testId="input-surname"
                errorLabel={
                  errors.surname?.message && t(errors.surname.message as string)
                }
              />
            </FlexContainer>
          </UserPanelProfile>
          <UserPanelProfile title={t('Account settings')}>
            <FlexContainer columnGap="4.8">
              <FormProfileInput
                type="email"
                {...register('email')}
                value={data?.user.personalInformation.email}
                disabled={true}
                label={t('Email')}
                iconLeft="Envelope"
                testId="input-email"
              />
            </FlexContainer>
          </UserPanelProfile>
          <UserPanelProfile title={t('Language & Timezone')}>
            <FlexContainer columnGap="4.8">
              <Controller
                control={control}
                name="language"
                render={({ field, fieldState }) => (
                  <FormControlDropdown
                    overlay
                    {...field}
                    {...fieldState}
                    label={t('Language')}
                    options={locales}
                    iconLeft="Flag"
                    disabled={!canEdit}
                    errorLabel={
                      errors.language?.message &&
                      t(errors.language.message as string)
                    }
                  />
                )}
              />
              <Controller
                name="timezone"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControlDropdownSearch
                    overlay
                    {...field}
                    {...fieldState}
                    label={t('Timezone')}
                    options={timezones}
                    iconLeft="MapTrifold"
                    disabled={!canEdit}
                    locale={i18n.language}
                    errorLabel={
                      errors.timezone?.message &&
                      t(errors.timezone.message as string)
                    }
                  />
                )}
              />
            </FlexContainer>
          </UserPanelProfile>
          <UserPanelProfile title={t('Group management')}>
            <FlexContainer columnGap="4.8">
              <Controller
                control={control}
                name="groups"
                render={({ field, fieldState }) => (
                  <Dropdown
                    overlay
                    {...field}
                    {...fieldState}
                    label={t('Groups')}
                    options={optionsGroups}
                    disabled={!canEdit}
                    multiple
                    errorLabel={
                      errors.groups?.message &&
                      t(errors.groups.message as string)
                    }
                  />
                )}
              />
              <Controller
                name="role"
                control={control}
                render={({ field, fieldState }) => (
                  <Dropdown
                    overlay
                    {...field}
                    {...fieldState}
                    label={t('Role')}
                    options={optionsRoles}
                    helpLabel={t(
                      'Assign at least a role to a user is mandatory'
                    )}
                    disabled={!canEdit}
                    errorLabel={
                      errors.role?.message && t(errors.role.message as string)
                    }
                  />
                )}
              />
            </FlexContainer>
          </UserPanelProfile>
        </UserPanelCardContent>
        <CardBaseFooter hasPadding>
          {canEdit ? (
            <Button
              testId="button-save"
              onClick={() => handleSubmit(onSumbit)()}
              disabled={loading}
            >
              {t('Save')}
            </Button>
          ) : (
            <Button onClick={() => setCanEdit(!canEdit)} testId="button-edit">
              {t('Edit')}
            </Button>
          )}
        </CardBaseFooter>
      </UserPanelCard>
    </UserPanelStyles>
  );
};
