import styled from 'styled-components/macro';

export const InfoComment = styled.div`
  margin-left: 2.65rem;
`;

export const IconReview = styled.div`
  margin-right: 1rem;
`;

export const InfoReview = styled.div`
  display: flex;
  margin-bottom: 0.75rem;
`;
