import React from 'react';
import { LottieContent } from './Styles';

type Props = {
  animation: Record<string, string | unknown>;
};

export const ModalGuideAnimation = ({ animation }: Props) => {
  return <LottieContent animation={animation} />;
};
