/* eslint-disable @typescript-eslint/no-explicit-any */
import { lazy } from 'react';
import { LayoutProvider, useAuth } from '@backoffice/providers';
import { Navigate } from 'react-router-dom';

const Page404 = lazy(() => import('@backoffice/pages/Error404'));

export const ProtectedRoute = ({ component: Component, app, flags }: any) => {
  const { isAuthenticated } = useAuth();

  const isActive = () => !flags || !flags.some((element: boolean) => !element);

  return (
    <>
      {isActive() ? (
        isAuthenticated ? (
          <LayoutProvider app={app}>
            <Component />
          </LayoutProvider>
        ) : (
          <Navigate to="/login" />
        )
      ) : (
        <Page404 />
      )}
    </>
  );
};
