import { useState } from 'react';
import { RoutesName } from '@backoffice/state/constants';
import { useNavigate } from 'react-router-dom';
import { QueueDataType } from './model';
import { QueueId } from './QueueId';
import { QueueTable } from './QueueTable';
import { VideoCallTabsWrapper } from './Styles';

export const QueueTab = () => {
  const [showId, setShowId] = useState<boolean>(false);
  const [innerInfo, setInnerInfo] = useState<QueueDataType>({
    id: '',
  });
  const navigate = useNavigate();

  return (
    <VideoCallTabsWrapper columnGap="1.6">
      <QueueTable
        setInnerInfo={setInnerInfo}
        show={showId}
        onShowId={(showId) => setShowId(showId)}
      />
      <QueueId
        innerInfo={innerInfo}
        show={showId}
        onAttend={() => {
          navigate(`${RoutesName.video}/${innerInfo.id}`);
        }}
        onChangeShow={(showId) => setShowId(showId)}
      />
    </VideoCallTabsWrapper>
  );
};
