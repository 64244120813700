import { Input } from '@facephi/ui-react';
import styled from 'styled-components/macro';

export const ReportingGrid = styled.div`
  display: grid;
  column-gap: 3rem;
  row-gap: 4rem;
  grid-template-columns: repeat(auto-fill, minmax(29rem, 1fr));
  margin-top: 6rem;
`;
export const ReportingInput = styled(Input)`
  width: 4rem;
`;
