import {
  FlexContainer,
  FlexContainerProps,
  setColorTheme,
  setGradientTheme,
} from '@facephi/ui-react';
import styled from 'styled-components/macro';

export const LayoutCornerStyles = styled(FlexContainer)`
  background-color: ${setColorTheme('grey50')};
  width: 100%;
  position: relative;
  padding-top: 9rem;
  padding-bottom: 2rem;

  > svg {
    width: 18rem;
    min-width: 18rem;
    height: 5.6rem;
    min-height: 5.6rem;
  }

  @media (max-width: 1280px) {
    padding-top: 5rem;
    row-gap: 2rem;
  }
`;

const LayoutCorner = styled.div`
  position: fixed;
  background-image: ${setGradientTheme('primary')};
`;

const CORNER_RADIUS = '3.2rem';

export const CornerLeft = styled(LayoutCorner)`
  top: 15.2rem;
  left: 0;
  width: 10.2rem;
  height: 14.5rem;
  border-radius: 0 ${CORNER_RADIUS} ${CORNER_RADIUS} 0;
  box-shadow: 60px 62px 140px 0 rgba(125, 80, 250, 0.18);
`;
export const CornerTopRight = styled(LayoutCorner)`
  top: 5.8rem;
  right: 0;
  width: 10.7rem;
  height: 14.5rem;
  border-radius: ${CORNER_RADIUS} 0 0 ${CORNER_RADIUS};
  box-shadow: -90px 62px 140px 0 rgba(125, 80, 250, 0.18);
`;

export const CornerBottomRight = styled(LayoutCorner)`
  bottom: 0;
  right: 0;
  width: 15rem;
  height: 17rem;
  border-radius: ${CORNER_RADIUS} 0 0 0;
  box-shadow: 60px 62px 140px 0 rgba(125, 80, 250, 0.18);
`;

export const LayoutCornerModal: React.FC<FlexContainerProps> = styled(
  FlexContainer
)`
  background: ${setColorTheme('white')};
  border-radius: 0.8rem;
  padding: 4.8rem 3.6rem;
  box-shadow: 0 5px 10px 0 ${setColorTheme('grey100')};
  border: solid 1px ${setColorTheme('grey100')};
  z-index: 1;
  width: 60rem;

  @media (max-width: 1280px) {
    padding: 2.8rem 2.6rem;
  }
`;
