import { useCommon } from '../providers';
import { SCOPES, Roles, PERMISSIONS } from '../state/model';

export function usePermissions(scopes?: SCOPES[]) {
  const { role } = useCommon();

  const permissions = role ? PERMISSIONS[role] : PERMISSIONS[Roles.User];

  const hasPermission = (scopes: SCOPES[]) => {
    const scopesMap: { [key: string]: boolean } = {};
    if (scopes) {
      scopes.forEach((scope: string) => {
        scopesMap[scope] = true;
      });

      return permissions.some((permission) => scopesMap[permission]);
    } else {
      return false;
    }
  };

  const permissionGranted = scopes ? hasPermission(scopes) : false;

  return {
    permissionGranted,
    hasPermission,
  };
}
