import { gql } from '@apollo/client';

export const searchOperations = gql`
  query searchOperations(
    $status: EntityStatus
    $fromTimestamp: String
    $limit: Int
    $text: String
    $cursor: String
    $family: [OperationType!]
  ) {
    operations: searchOperations(
      after: $cursor
      parameters: {
        status: $status
        fromTimestamp: $fromTimestamp
        limit: $limit
        text: $text
        include: $family
      }
    ) {
      edges {
        node {
          deleted
          operationId
          family: type
          customerId
          creationTime
          lastUpdateTime
          currentStep
          steps
          stepsFlow
          status
          results {
            type
            status
            reason
          }
          assets {
            type
            url
            contentType
          }
          wallet {
            type
            walletId
            source
          }
          credentials {
            type
            walletId
            credentialId
            credentialType
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const getOperation = gql`
  query getOperation($tenantId: [String!], $id: String!) {
    operation: getOperationDetails(operationId: $id, tenantId: $tenantId) {
      family: type
      currentStep
      operationId
      lastUpdateTime
      creationTime
      steps
      status
      results {
        type
        status
        reason
      }
      details {
        ... on AuthenticationDetails {
          assets {
            url
            contentType
            type
          }
          device {
            type
            model
            osVersion
            brand
            deviceId
          }
          coordinates {
            latitude
            longitude
          }
          facialAuthenticateResult {
            similarity
            authStatus
          }
          facialLivenessResult {
            diagnostic
            description
          }
          ocrData {
            personalInformation {
              name
              surname
              nationality
              documentNumber
              birthDate
              birthPlace
              address
              city
            }
            source
            default
            documentDataFields {
              key
              value
            }
            documentImages {
              type
              url
              contentType
            }
          }
          wallet {
            type
            walletId
            source
          }
          credentials {
            type
            walletId
            credentialId
            credentialType
          }
        }
        ... on OnboardingDetails {
          coordinates {
            latitude
            longitude
          }
          facialAuthenticateResult {
            similarity
            authStatus
          }
          facialLivenessResult {
            diagnostic
            description
          }
          device {
            type
            model
            osVersion
            brand
            deviceId
          }
          ocrData {
            personalInformation {
              name
              surname
              nationality
              documentNumber
              birthDate
              birthPlace
              address
              city
            }
            source
            default
            documentDataFields {
              key
              value
            }
            documentImages {
              type
              url
              contentType
            }
          }
          securityData {
            verificationStatus
            scanStatus
            scanSource
            dataPositions
            documentValidation
            hologramValidation
            mrzCodeVerification
            featuresVerification
            signatureVerification
            documentType
            documentSubType
            country
            rejectReason
            microprintVerification
          }
          assets {
            url
            contentType
            type
          }
          wallet {
            type
            walletId
            source
          }
          credentials {
            type
            walletId
            credentialId
            credentialType
          }
        }
      }
    }
  }
`;

export const getTimeLine = gql`
  query searchOnboardingEvents($parameters: AuditSearchParameters) {
    timeline: searchAuditEvents(parameters: $parameters) {
      events {
        ... on OnboardingAudit {
          eventId
          clientTimestamp
          metadata: payload {
            ... on AssetPayload {
              type
              url
              contentType
            }
            ... on OnboardingPayload {
              type
              screen
              event
              value
            }
            ... on OperationResult {
              type
              status
              reason
            }
            ... on FacialAuthenticateResult {
              type
              similarity
              authStatus
              source
            }
            ... on FacialLivenessResult {
              type
              diagnostic
              description
            }
            ... on TeseoWallet {
              type
              walletId
              source
            }
            ... on TeseoCredential {
              type
              walletId
              credentialId
              credentialType
            }
          }
        }
      }
    }
  }
`;

export const getTimeLineAuthentication = gql`
  query searchOnboardingEvents($parameters: AuditSearchParameters) {
    timeline: searchAuditEvents(parameters: $parameters) {
      events {
        ... on AuthenticationAudit {
          eventId
          date: clientTimestamp
          metadata: payload {
            ... on AssetPayload {
              type
              url
              contentType
            }
            ... on AuthenticationPayload {
              type
              screen
              event
              value
            }
            ... on OperationResult {
              type
              status
              reason
            }
            ... on TeseoWallet {
              type
              walletId
              source
            }
            ... on TeseoCredential {
              type
              walletId
              credentialId
              credentialType
            }
          }
        }
      }
    }
  }
`;
