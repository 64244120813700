import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useFlags } from '@facephi/inphinite-common';
import {
  ButtonIcon,
  OptionMenu,
  OptionMenuItem,
  PositionX,
  TData,
  TableSelectionHandle,
  TableSelectionLegacy,
  useToast,
} from '@facephi/ui-react';
import { capitalize, cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { CellProps, Column } from 'react-table';
import { OptionMenuItemWrapper, UserTableCellStyles } from './Styles';
import { UserAvatar } from './UserAvatar';
import { useRoles } from '../../hooks';
import { RoutesName } from '../../state/constants';
import { RoleDto, UserListDto } from '../../state/model';
import {
  assignRoles as assignRolesMutation,
  deleteUserMutation,
} from '../../state/mutations';
import { getUsers } from '../../state/queries';
import { GroupBoxList } from '../GroupBoxList';
import { RoleInfoLabel } from '../roleInfoBox';
import { GroupBoxStyle } from '../Styles';

type UserTableData = {
  userId: string;
  status: string;
  username: string;
  avatar?: ReactElement;
  groups?: ReactElement;
  roleId: string;
  roleName: string;
};

type SearchUsersData = {
  users: { node: UserListDto }[];
  pageInfo: {
    endCursor: string;
    hasNextPage: boolean;
  };
};

export const UserManagementTableUser = ({
  userSearch = '',
  onSelectedRows,
  role,
}: {
  userSearch?: string;
  onSelectedRows(rows: number): void;
  role?: string | null;
}) => {
  const tableRef = useRef<TableSelectionHandle>(null);
  const { t } = useTranslation();
  const { toastManager } = useToast();

  const { roles } = useRoles();
  const { demo } = useFlags();

  const [rows, setRows] = useState<UserTableData[]>([] as UserTableData[]);

  useEffect(() => {
    onSelectedRows(rows.length);
  }, [rows]);

  useEffect(() => {
    if (role && rows.length) {
      onUpdateUsers(
        role,
        rows.map(({ userId }: UserTableData) => userId),
        true
      );
    }
  }, [role]);

  const { data, loading, fetchMore } = useQuery<{
    listUsers: SearchUsersData;
  }>(getUsers, {
    variables: {
      username: userSearch,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  useEffect(() => {
    tableRef?.current?.reset();
  }, [userSearch]);

  const [assignRoles] = useMutation(assignRolesMutation);
  const [deleteUser] = useMutation(deleteUserMutation);

  const navigate = useNavigate();

  const onDeleteUser = async (id: string) => {
    await deleteUser({
      variables: { userId: id },
      update: (cache) => {
        const response = cache.readQuery<{
          listUsers: SearchUsersData;
        }>({
          query: getUsers,
          variables: {
            username: userSearch,
          },
        });
        const queryData = cloneDeep(response);

        if (queryData) {
          queryData.listUsers.users = queryData.listUsers.users.filter(
            ({ node }: { node: UserListDto }) => node.id !== id
          );

          cache.writeQuery({
            query: getUsers,
            data: queryData,
            variables: {
              username: userSearch,
            },
          });
        }
      },
    });
    toastManager({
      type: 'success',
      message: t('User deleted'),
      testId: 'toast-delete-user',
    });
  };

  const onUpdateUsers = useCallback(
    async (roleId: string, usersId: string[], isMultiple?: boolean) => {
      const newRole = roles.find((item) => item.id === roleId) as RoleDto;
      let type, message;

      try {
        await assignRoles({
          variables: {
            roleId,
            usersId,
          },
          update: (cache) => {
            const response = cache.readQuery<{
              listUsers: SearchUsersData;
            }>({
              query: getUsers,
              variables: {
                username: userSearch,
              },
            });
            const newData = cloneDeep(response);

            if (newData) {
              usersId.forEach((id) => {
                const position = newData.listUsers.users.findIndex(
                  ({ node }: { node: UserListDto }) => node.id === id
                );
                if (position >= 0) {
                  newData.listUsers.users[position].node = {
                    ...newData.listUsers.users[position].node,
                    role: newRole,
                  };
                }
              });

              cache.writeQuery({
                query: getUsers,
                data: newData,
              });
            }
          },
        });
        type = 'success';
        message = 'userUpdated';
      } catch (e) {
        type = 'error';
        message = 'userUpdatedError';
      }

      toastManager({
        type: type as 'success' | 'error',
        message: t(message, {
          count: usersId.length,
          role: t(newRole?.name),
        }),
        testId: 'toast-updated-user',
      });

      if (isMultiple) {
        tableRef?.current?.reset();
      }
    },
    [roles, userSearch]
  );

  const handlePagination = async (): Promise<boolean> => {
    if (fetchMore && !loading) {
      await fetchMore({
        variables: {
          username: userSearch,
          cursor: data?.listUsers?.pageInfo?.endCursor,
        },
      });
    }
    return Promise.resolve(true);
  };

  const columns: TData[] = useMemo(
    () => [
      {
        Header: t('Username'),
        accessor: 'username',
        maxWidth: 250,
        minWidth: 150,
        Cell: (props: CellProps<UserTableData>) => {
          return (
            <UserTableCellStyles alignItems="center">
              {props.row.original.avatar} {props.row.original.username}
            </UserTableCellStyles>
          );
        },
      },
      {
        Header: t('Group name'),
        accessor: 'groups',
        minWidth: 400,
      },
      {
        Header: t('Role'),
        accessor: 'roleName',
        maxWidth: 150,
        Cell: (props: CellProps<UserTableData>) => {
          return (
            <GroupBoxStyle columnGap="0.8" alignItems="center">
              <RoleInfoLabel
                semibold
                fontSize="12"
                role={props.row.original.roleName}
              >
                {t(props.row.original.roleName)}
              </RoleInfoLabel>
              <OptionMenu
                testId="role-box"
                renderItem={
                  <ButtonIcon iconName="CaretDown" variant="text" size="S" />
                }
              >
                {roles.map(({ name, id }: RoleDto) => (
                  <OptionMenuItemWrapper
                    onClick={() =>
                      onUpdateUsers(id, [props.row.original.userId])
                    }
                    active={props.row.original.roleId === id}
                    disabled={props.row.original.roleId === id}
                    testId={`role-${id}`}
                    key={id}
                  >
                    {t(name)}
                  </OptionMenuItemWrapper>
                ))}
              </OptionMenu>
            </GroupBoxStyle>
          );
        },
      },
      {
        Header: t('Actions'),
        accessor: 'action',
        maxWidth: 60,
        Cell: (props: CellProps<UserTableData>) => {
          return (
            <span data-tour="settings_user-action">
              <OptionMenu
                hidePanelOnClick
                renderItem={<ButtonIcon variant="text" iconName="ListPlus" />}
                positionX={PositionX.end}
                testId={`action-user-${props.row.id}`}
              >
                <OptionMenuItem
                  onClick={() =>
                    navigate(
                      generatePath(RoutesName.userManagementProfile, {
                        id: props.row.original.userId,
                      })
                    )
                  }
                >
                  {t('See profile')}
                </OptionMenuItem>
                {demo && <OptionMenuItem>{t('Move group')}</OptionMenuItem>}
                {demo && (
                  <OptionMenuItem
                    onClick={() =>
                      navigate(
                        generatePath(RoutesName.permissions, {
                          type: 'users',
                          id: props.row.original.userId,
                        })
                      )
                    }
                  >
                    {t('Set up permission')}
                  </OptionMenuItem>
                )}
                <OptionMenuItem
                  onClick={() => onDeleteUser(props.row.original.userId)}
                  testId="button-delete-user"
                >
                  {t('Delete')}
                </OptionMenuItem>
              </OptionMenu>
            </span>
          );
        },
      },
    ],
    [roles, onUpdateUsers]
  );

  const tableData = useMemo(() => {
    return data
      ? data.listUsers.users.map(({ node }: { node: UserListDto }) => ({
          userId: node.id,
          status: capitalize(node.status),
          username: node.username,
          avatar: <UserAvatar avatar={node.personalInformation.avatar} />,
          groups: <GroupBoxList groups={node.groups} />,
          roleId: node.role.id,
          roleName: node.role.name,
        }))
      : [];
  }, [data, roles]);

  return (
    <TableSelectionLegacy
      ref={tableRef}
      columns={columns as Column<TData>[]}
      data={tableData}
      loading={data?.listUsers?.pageInfo?.hasNextPage === undefined && loading}
      hasMore={!loading && data?.listUsers?.pageInfo?.hasNextPage}
      fetchMore={handlePagination}
      selectedRows={
        data
          ? rows.map(({ userId }) =>
              data.listUsers.users.findIndex(({ node }) => node.id === userId)
            )
          : []
      }
      onSelectedRowsChange={setRows}
      testId="table-users"
    />
  );
};
