import { CardBase, PositionX } from '@facephi/ui-react';
import styled from 'styled-components';

const transformMapTop = {
  left: {
    transformOrigin: 'bottom right',
    transformTranslate: 'translate(15%, 0%)',
  },
  right: {
    transformOrigin: 'bottom left',
    transformTranslate: 'translate(-15%, 0%)',
  },
  center: {
    transformOrigin: 'bottom',
    transformTranslate: 'translate(0%, 15%)',
  },
  start: {
    transformOrigin: 'bottom left',
    transformTranslate: 'translate(-15%, 0%)',
  },
  end: {
    transformOrigin: 'bottom right',
    transformTranslate: 'translate(15%, 0%)',
  },
};

const transformMapBottom = {
  left: {
    transformOrigin: 'top right',
    transformTranslate: 'translate(15%, -15%)',
  },
  right: {
    transformOrigin: 'top left',
    transformTranslate: 'translate(-15%, 0%)',
  },
  center: {
    transformOrigin: 'top',
    transformTranslate: 'translate(0%, -15%)',
  },
  start: {
    transformOrigin: 'top left',
    transformTranslate: 'translate(-15%, 0%)',
  },
  end: {
    transformOrigin: 'top right',
    transformTranslate: 'translate(15%, -15%)',
  },
};

export const assignTransformRules = {
  top: (positionX: PositionX) => {
    return transformMapTop[positionX];
  },
  bottom: (positionX: PositionX) => {
    return transformMapBottom[positionX];
  },
  center: () => {
    return {
      transformOrigin: 'center',
      transformTranslate: 'translate(0%, 0%)',
    };
  },
};

export const HelpInfoTooltipStyles = styled(CardBase)<{
  titleHeader?: boolean;
}>`
  max-width: 50rem;
  width: max-content;
`;
