import { useEffect, useRef, useState } from 'react';
import { Button, Portal, PositionX, PositionY } from '@facephi/ui-react';
import { Transition, animated, config } from 'react-spring';
import { HelpInfoTooltipProps } from './model';
import { HelpInfoTooltipStyles, assignTransformRules } from './Styles';

export const HelpInfoTooltip = ({
  title,
  children,
  className,
  testId,
  onChangeShow,
  positionX = PositionX.center,
  positionY = PositionY.bottom,
  overlayBlur,
  iconName,
  label,
  color,
}: HelpInfoTooltipProps) => {
  const [innerShow, setInnerShow] = useState<boolean>(false);
  const buttonMenuRef = useRef(null);

  const hiddenAnimation = {
    opacity: 0,
    transform: `scale(0) ${
      assignTransformRules[positionY](positionX).transformTranslate
    }`,
  };
  const visibleAnimation = {
    opacity: 1,
    transform: `scale(1) translate(0%, 0%)`,
  };

  useEffect(() => {
    setInnerShow(innerShow);
  }, [innerShow]);

  useEffect(() => {
    onChangeShow && onChangeShow(innerShow);
  }, [innerShow]);

  const onClose = () => {
    setInnerShow(false);
  };

  return (
    <>
      <Button
        variant="text"
        iconName={iconName}
        ref={buttonMenuRef}
        onClick={() => setInnerShow(true)}
        color={color}
      >
        {label ? label : ''}
      </Button>
      <Transition
        items={innerShow}
        config={config.stiff}
        from={hiddenAnimation}
        enter={visibleAnimation}
        leave={hiddenAnimation}
      >
        {(styles, innerShow) =>
          innerShow && (
            <Portal
              show
              positionX={positionX}
              positionY={positionY}
              actionRef={buttonMenuRef}
              widthAuto
              overlay
              overlayBlur={overlayBlur}
              onClickOutside={onClose}
            >
              <animated.div
                style={{
                  ...styles,
                  transformOrigin:
                    assignTransformRules[positionY](positionX).transformOrigin,
                }}
              >
                <HelpInfoTooltipStyles
                  testId={testId}
                  className={className}
                  // onCloseCard={onClose}
                  // noPaddingContent
                >
                  {children}
                </HelpInfoTooltipStyles>
              </animated.div>
            </Portal>
          )
        }
      </Transition>
    </>
  );
};
