/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  fromPromise,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { useAuth } from '@backoffice/providers';
import { cache } from '@backoffice/state/cache';

type IProps = {
  children: React.ReactNode;
};

export const ApiProvider = ({ children }: IProps) => {
  const apiUrl: string = (window as any)._env_.REACT_APP_API_URL;
  const wsUrl: string = (window as any)._env_.REACT_APP_API_WS_URL;

  const { logout, token, refreshToken, handleRefreshToken, tenant } = useAuth();

  const httpLink = createHttpLink({
    uri: `${apiUrl}graphql`,
    credentials: 'same-origin',
  });

  let splitLink;

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : '',
        'Tenant-Id': tenant.id,
      },
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ extensions }) => {
        const statusCode = extensions?.exception?.response?.statusCode;

        if (statusCode === 401) {
          return fromPromise(
            handleRefreshToken()
              .catch(() => {
                logout();
              })
              .then((updatedToken) => {
                const oldHeaders = operation.getContext().headers;
                operation.setContext({
                  headers: {
                    ...oldHeaders,
                    authorization: `Bearer ${updatedToken}`,
                  },
                });
              })
          );
        }

        return false;
      });
  });

  if (wsUrl && wsUrl !== '' && token) {
    splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      new WebSocketLink({
        uri: `${wsUrl}graphql`,
        options: {
          reconnect: true,
          connectionParams: {
            headers: {
              authorization: `Bearer ${token}`,
            },
            token,
            refreshToken,
          },
        },
      }),
      authLink.concat(httpLink)
    );
  } else {
    splitLink = authLink.concat(httpLink);
  }

  const link = ApolloLink.from([
    errorLink,
    splitLink || authLink.concat(httpLink),
  ]);

  const client = new ApolloClient({
    link,
    cache,
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
