import { useMemo, useState } from 'react';
import { AppName, AppsWithIcon, RoutesName } from '@backoffice/state/constants';
import { AppsIconsType } from '@backoffice/state/model';
import { SCOPES, usePermissions } from '@facephi/inphinite-common';
import { FlexContainer, Icon, IconWrapper, Label } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppsAsideNavType, AsideNavProps, AsideNavType } from './model';
import {
  AsideNavBottomButton,
  AsideNavBottomPanel,
  AsideNavBottomStyles,
} from './Styles';

export const AsideNavBottom = ({ app }: AsideNavProps) => {
  const [hoverApps, setHoverApps] = useState<string>('');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();

  const builderUrl: string = (window as any)._env_.REACT_APP_BUILDER_URL;

  const apps: AsideNavType[] = [
    {
      app: AppName.operations,
      testId: 'dashboard-app',
      onClick: () => {
        navigate(RoutesName.dashboard);
      },
      visible: hasPermission([SCOPES.canViewOperations]),
    },
    {
      app: AppName.builder,
      testId: 'builder-app',
      onClick: () => {
        window.open(builderUrl, '_self');
      },
      visible: hasPermission([SCOPES.canViewBuilder]),
    },
    {
      app: AppName.ux,
      testId: 'ux-app',
      onClick: () => {
        navigate(RoutesName.uxAnalytic);
      },
      visible: hasPermission([SCOPES.canViewUX]),
    },
    {
      app: AppName.privacy,
      testId: 'privacy-app',
      onClick: () => {
        navigate(RoutesName.privacy);
      },
      visible: hasPermission([SCOPES.canViewPrivacy]),
    },
    {
      app: AppName.ai,
      testId: 'ai-app',
      onClick: () => {
        navigate(RoutesName.ai);
      },
      visible: hasPermission([SCOPES.canViewAI]),
    },
    {
      app: AppName.antiFraud,
      testId: 'anti-fraud-app',
      onClick: () => {
        navigate(RoutesName.antifraud);
      },
      visible: hasPermission([SCOPES.canViewAntifraud]),
    },
    {
      app: AppName.videoAssistance,
      testId: 'video-support-app',
      onClick: () => {
        navigate(RoutesName.videoCalls);
      },
      visible: hasPermission([SCOPES.canViewVideo]),
    },
    {
      app: AppName.audit,
      testId: 'audit-app',
      onClick: () => {
        navigate(RoutesName.audit);
      },
      visible: hasPermission([SCOPES.canViewAudit]),
    },
    {
      app: AppName.userManagement,
      testId: 'user-management-app',
      onClick: () => {
        navigate(RoutesName.userManagement);
      },
      visible: hasPermission([SCOPES.canViewUserManagment]),
    },
    {
      app: AppName.behaviour,
      testId: 'behaviour-app',
      onClick: () => {
        navigate(RoutesName.behaviour);
      },
      visible: hasPermission([SCOPES.canViewBehaviour]),
    },
    {
      app: AppName.ciam,
      testId: 'ciam-app',
      onClick: () => {
        navigate(RoutesName.ciam);
      },
      visible: hasPermission([SCOPES.canViewCiam]),
    },
  ];

  const customApps: AppsAsideNavType[] = useMemo(
    () =>
      apps
        .filter((item) => item.visible)
        .map((item) => {
          const config = AppsWithIcon.find(
            (app) => app.label === item.app
          ) as AppsIconsType;
          return { ...item, ...config };
        }),
    [apps]
  );

  const currentApp = useMemo(
    () => customApps.find((item) => item.label === app),
    [app, customApps]
  );

  return (
    <AsideNavBottomStyles>
      <FlexContainer
        as="ul"
        flexDirection="column"
        justifyContent="center"
        rowGap="2.6"
      >
        {currentApp && (
          <FlexContainer
            flexDirection="column"
            alignItems="center"
            rowGap="0.6"
          >
            <IconWrapper
              color={currentApp.color}
              iconName={currentApp.iconName}
              invertBackground
              size="XL"
            />
            <Label fontSize="10" negative textAlign="center">
              {t(currentApp.label)}
            </Label>
          </FlexContainer>
        )}
        <AsideNavBottomPanel
          itemsLength={customApps.length}
          widthAuto
          hidePanelOnClick
          renderItem={
            <AsideNavBottomButton
              forwardedAs="button"
              flexDirection="column"
              alignItems="center"
              rowGap="0.6"
              data-test="menu-application"
            >
              <FlexContainer alignItems="center" justifyContent="center">
                <Icon iconName="SquaresFour" color="white" size="32" />
              </FlexContainer>
              <Label fontSize="10" negative>
                {t('Application')}
              </Label>
            </AsideNavBottomButton>
          }
        >
          {customApps.map((item) => (
            <FlexContainer
              flexDirection="column"
              alignItems="center"
              as="li"
              rowGap="0.6"
              onClick={item.onClick}
              data-test={item.testId}
              key={item.label}
              color={item.color}
              onMouseEnter={() => setHoverApps(item.label)}
              onMouseLeave={() => setHoverApps('')}
            >
              <IconWrapper
                iconName={item.iconName}
                color={item.color}
                invertBackground={hoverApps === item.label}
                size="L"
              />
              <Label fontSize="10" textAlign="center">
                {t(item.label)}
              </Label>
            </FlexContainer>
          ))}
        </AsideNavBottomPanel>
      </FlexContainer>
    </AsideNavBottomStyles>
  );
};
