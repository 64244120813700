import { browserList } from '@backoffice/state/constants';
import { BrowserListProps } from '@backoffice/state/model';
import { Button, FlexContainer, Label } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { BrowserImage, LayoutCornerBackOffice } from './Styles';

export const BrowserNoValidate = () => {
  const { t } = useTranslation();

  return (
    <LayoutCornerBackOffice rowGap="4">
      <Label fontSize="18" semibold>
        {t('Improve your experience')}
      </Label>
      <div>
        <Label fontSize="12" textAlign="center">
          {t('You are using a web browser that is not supported')}
        </Label>
        <Label fontSize="12" textAlign="center">
          {t(
            'Try one of these options to have a better experience on FacePhi Identity Platform'
          )}
        </Label>
      </div>
      <FlexContainer flexDirection="column" rowGap="1.6">
        {browserList
          .filter((item) => item.installable)
          .map((item: BrowserListProps) => (
            <FlexContainer
              alignItems="center"
              justifyContent="space-between"
              columnGap="2"
              key={item.id}
            >
              <BrowserImage src={item.image} alt="logo" />

              <Label fontSize="14">{`${item.name} ${item.version}`}</Label>

              <Button as="a" variant="text" href={item.route}>
                {t('Install now')}
              </Button>
            </FlexContainer>
          ))}
      </FlexContainer>
    </LayoutCornerBackOffice>
  );
};
