import ReactFlow, {
  ArrowHeadType,
  FlowElement,
  Position,
} from 'react-flow-renderer';

const elements: FlowElement[] = [
  {
    id: '1',
    type: 'input',
    data: { label: 'Behaviour analytics' },
    position: { x: 0, y: 50 },
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
  },
  {
    id: '2',
    data: { label: 'Governamental service' },
    position: { x: 250, y: 50 },
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
  },
  {
    id: '3',
    data: { label: 'Behaviour analytics' },
    position: { x: 500, y: 50 },
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
  },
  {
    id: '4',
    data: { label: 'Facial biometric' },
    position: { x: 500, y: 150 },
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
  },
  {
    id: 'e1-2',
    source: '1',
    target: '2',
    arrowHeadType: ArrowHeadType.ArrowClosed,
  },
  {
    id: 'e2-3',
    source: '2',
    target: '3',
    arrowHeadType: ArrowHeadType.ArrowClosed,
  },
  {
    id: 'e2-4',
    source: '2',
    target: '4',
    animated: true,
    arrowHeadType: ArrowHeadType.ArrowClosed,
  },
];
export const Onboarding = () => (
  <div
    style={{
      height: 300,
      width: 700,
      backgroundColor: 'white',
      padding: 50,
    }}
  >
    <ReactFlow elements={elements} />
  </div>
);
