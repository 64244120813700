import { setColorTheme } from '@facephi/ui-react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';
import { ASIDENAV_WIDTH } from '../../state/constants';

export const StylesMenu = styled.div<{ isPreviewMode?: boolean }>`
  width: ${ASIDENAV_WIDTH};
  border-radius: 2.4rem;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  background-color: ${setColorTheme('grey800')};
  display: flex;
  align-items: center;
  padding: 2.9rem 1.3rem;
  flex-direction: column;
  z-index: 9999;
  left: 0;
  top: 0;
  height: 100%;

  ${(props) =>
    props.isPreviewMode
      ? `
    position: relative;
  `
      : `
  position: fixed;
  box-shadow: 0 5px 10px -1px ${setColorTheme('blackAndroid')};

  `}
`;

export const StylesMenuLink = styled('a')`
  height: 6rem;
  width: 6rem;
  border-radius: 0.8rem;
  margin-bottom: 1.5rem;
  overflow: hidden;

  > * {
    height: 100%;
    width: 100%;
  }

  > img {
    object-fit: cover;
  }
`;

export const StylesSeparator = styled.div`
  height: 0.2rem;
  width: 100%;
  background-color: ${setColorTheme('grey500')};
`;

export const StylesMenuNav = styled.nav`
  display: flex;
  width: 100%;
  flex: 1;
  justify-content: center;
  margin-top: 1rem;
`;

export const StyledMenuItem = styled(NavLink)`
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 0.8rem;

  &[aria-current],
  &:hover {
    > div {
      background-color: ${setColorTheme('grey600')};
    }
  }
  > div {
    width: 4rem;
    height: 4rem;
    border-radius: 0.8rem;
  }
`;
