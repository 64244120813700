import { AppName } from '@backoffice/state/constants';

export type ModalApps = {
  app: AppName;
  source: Record<string, unknown>;
  title: string;
  message: string;
  onNext?: AppName;
  onPrev?: AppName;
};

export type ModalGuideParams = {
  getLottie(text: string): Record<string, unknown>;
};

export const modalGuideApps = ({
  getLottie,
}: ModalGuideParams): ModalApps[] => [
  {
    app: AppName.operations,
    source: getLottie('operations'),
    title: `${AppName.operations} at first sight`,
    message:
      'Have a look of all operations (Overview dashboard, KPI, graphic and pie charts). Review them and see every details about document or biometry.',
  },
];
