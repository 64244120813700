import { DragEvent } from 'react';
import { ButtonIcon, Icon, Label } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { PermissionsBoxStyleProps } from './model';
import { PermissionsBoxStyle } from './Styles';

type Props = PermissionsBoxStyleProps & {
  title: string;
  onClick?(): void;
  level?: number;
  onDragStart?(event: DragEvent<HTMLElement>): void;
};

export const PermissionsBox = ({
  title,
  onClick,
  isClosable,
  isDraggable,
  onDragStart,
  isEdit,
  disabled,
  level = 0,
}: Props) => {
  const { t } = useTranslation();

  return (
    <PermissionsBoxStyle
      alignItems="center"
      columnGap="1.2"
      onDragStart={onDragStart}
      draggable={isDraggable && !disabled}
      isClosable={isClosable}
      isEdit={isEdit}
      disabled={disabled}
      style={{ marginLeft: `${level * 2}rem` }}
    >
      {!level && (
        <Icon
          iconName="ListBullets"
          size="24"
          color={isEdit ? 'grey800' : isDraggable ? 'grey600' : 'white'}
        />
      )}
      <Label
        fontSize="14"
        semibold
        negative={isDraggable && isEdit ? true : !isDraggable && !isEdit}
      >
        {t(title)}
      </Label>
      {(isEdit || isClosable) && (
        <ButtonIcon variant="text" onClick={onClick} iconName="XCircle" />
      )}
    </PermissionsBoxStyle>
  );
};
