import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TableEmpty } from '../components/TableEmpty';
import { TableError } from '../components/TableError';

type Props<T> = {
  data?: T[];
  hasError?: boolean;
  isFilter?: boolean;
  isLoading?: boolean;
  emptyUsers?: boolean;
  emptyTenants?: boolean;
  onRetry?(): void;
};

export function useEmptyState<T>({
  data,
  hasError,
  isLoading,
  onRetry,
  isFilter,
  emptyTenants,
  emptyUsers,
}: Props<T>) {
  const { t } = useTranslation();

  const emptyState = useMemo(() => {
    if (isLoading) {
      return undefined;
    }

    if (hasError) {
      return {
        title: t('Alert!'),
        subtitle: t('Something went wrong.'),
        description: t('Please retry.'),
        image: <TableError />,
        button: { text: t('Retry'), onClick: onRetry },
      };
    }
    if (isFilter && !data?.length) {
      return {
        title: t('Sorry, we don’t find what you are searching.'),
        subtitle: t('Try again changing the search parameters.'),
        button: { text: t('Retry'), onClick: onRetry },
        image: <TableEmpty />,
      };
    }
    if (emptyTenants) {
      return {
        title: t('Add tenants to this user'),
        subtitle: t('Click on top and start adding tenants.'),
        image: <TableEmpty />,
      };
    }

    if (emptyUsers) {
      return {
        title: t('Add users to this tenant'),
        subtitle: t('Click on top and start adding users.'),
        image: <TableEmpty />,
      };
    }

    if (!data?.length) {
      return {
        title: t('There are no data yet.'),
        subtitle: '',
        button: { text: t('Reload page'), onClick: onRetry },
        image: <TableEmpty />,
      };
    }
    return undefined;
  }, [data, hasError, isLoading, isFilter, emptyTenants, emptyUsers]);

  return { emptyState };
}
