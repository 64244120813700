import { Button, FlexContainer, Label, setColorTheme } from '@facephi/ui-react';
import styled from 'styled-components';
import { CardBaseStyleProps } from './model';

export const CardTitleStyle = styled(Label)`
  padding-bottom: 0.4rem;
`;

export const CardSubtitleStyle = styled(Label)`
  padding-bottom: 0.4rem;
  color: ${setColorTheme('grey300')};
`;

export const CardBaseContent = styled(FlexContainer)<{
  $noPadding?: boolean;
}>`
  padding: ${(props) => (props.$noPadding ? 0 : PADDING_CARD)};
  flex: 1;

  .echarts-for-react {
    & > div:first-of-type {
      width: 100% !important;

      & > canvas {
        width: 100% !important;
      }
    }
  }
`;

export const CardContainer = styled(CardBaseContent)`
  position: relative;
`;

export const CardFooter = styled(FlexContainer)`
  margin-top: auto;

  > a {
    font-size: 1.2rem;
    display: inline-block;
    font-weight: 600;
    color: ${setColorTheme('grey700')};
  }
`;

export const PADDING_CARD = '3rem';

export const CARD_BASE_HEADER_HEIGHT = '6rem';
export const CARD_BASE_HEADER_MARGIN_BOTTOM = '2.4rem';
export const CARD_BASE_FOOTER_PADDING = '2rem';

export const CardBaseStyle = styled(FlexContainer)<CardBaseStyleProps>`
  background: ${setColorTheme('white')};
  border-radius: 0.8rem;
  flex: ${(props) => (props.flex ? props.flex : 1)};
  ${(props) => props.onClick && `cursor:pointer;`}
  ${(props) =>
    !props.noShadow && `box-shadow: 0 5px 10px 0 ${setColorTheme('grey100')} `};
`;

export const CardBaseHeader = styled(FlexContainer)<{ $noBorder?: boolean }>`
  height: ${CARD_BASE_HEADER_HEIGHT};
  min-height: ${CARD_BASE_HEADER_HEIGHT};
  width: 100%;
  padding: 0 2rem;

  ${(props) =>
    !props.$noBorder && `border-bottom:1px solid ${setColorTheme('grey100')} `};
`;
export const CardBaseHeaderOperations = styled(CardBaseHeader)`
  padding: 2rem 2rem 0 2rem;
`;

export const CardBaseFooter = styled(FlexContainer)<{ noPadding?: boolean }>`
  height: ${CARD_BASE_HEADER_HEIGHT};
  min-height: ${CARD_BASE_HEADER_HEIGHT};
  align-items: center;
  justify-content: flex-end;
  padding: 0 ${(props) => (props.noPadding ? 0 : CARD_BASE_FOOTER_PADDING)};
  ${(props) =>
    !props.noPadding && `border-top: 1px solid ${setColorTheme('grey200')};`}
`;

export const CardBaseFooterButton = styled(Button)`
  width: 100%;
  height: 100%;
` as any;
