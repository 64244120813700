import React from 'react';
import { InphiniteThemeProvider } from '@facephi/ui-react';
import styled, { createGlobalStyle } from 'styled-components';
import { GlobalStyles } from '../styles/GlobalStyles';

type IProps = {
  children: React.ReactNode;
  style?: React.CSSProperties;
};

const StylesGlobal = createGlobalStyle`

  body {
    > div {
      height: 100%;
    }
  }

`;

export const WrapperTheme = ({ children, style }: IProps) => (
  <InphiniteThemeProvider>
    <GlobalStyles />
    <StylesGlobal />
    <div
      style={{
        display: 'flex',
        height: '500px',
        padding: '10px',
        ...style,
      }}
    >
      {children}
    </div>
  </InphiniteThemeProvider>
);

export const WrapperDarkTheme = ({ children, style }: IProps) => (
  <InphiniteThemeProvider>
    <GlobalStyles />
    <StylesGlobal />
    <div
      style={{
        display: 'flex',
        height: '500px',
        padding: '10px',
        backgroundColor: 'black',
        ...style,
      }}
    >
      {children}
    </div>
  </InphiniteThemeProvider>
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ColorItem = ({ background, color, label, hex, darkText }: any) => {
  return (
    <ColorsBox style={{ background: background }} darkText={darkText}>
      <p>{label}</p>
      <p>{hex}</p>
    </ColorsBox>
  );
};

export const ColorsBox = styled.div<{ darkText?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1.6rem;
  height: 100px;
  width: 200px;
  border-radius: 4px;
  color: ${(props) => (props.darkText ? 'black' : 'white')};
  p:first-of-type {
    text-transform: capitalize;
  }
  p {
    text-align: center;
  }
`;

export const ColorsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
  width: 100%;
`;
