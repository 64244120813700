import { AppsIconsType } from '@backoffice/state/model';

export type PermissionsBoxStyleProps = {
  isDraggable?: boolean;
  isEdit?: boolean;
  isClosable?: boolean;
  disabled?: boolean;
};

export type RawPermissionType = {
  id: string;
  label: string;
  parent?: string;
  name?: string;
  permissions?: Array<RawPermissionType>;
};

export type PermissionType = AppsIconsType & RawPermissionType;

export const TYPES_MANAGEMENT = ['users', 'groups'];
