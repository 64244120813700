import { FlexContainer, IconWrapper, Label } from '@facephi/ui-react';
import Skeleton from 'react-loading-skeleton';
import { WidgetItemProps } from './model';

export const WidgetItem = ({
  name,
  backgroundColor,
  className,
  iconName,
  testId,
  direction = 'column',
  size,
  onClick,
  shape,
  loading,
  labelSize,
  labelColor,
}: WidgetItemProps) => {
  const getLoadingWidget = () => (
    <>
      {shape === 'circle' ? (
        <Skeleton height={50} width={50} circle />
      ) : (
        <Skeleton height={50} width={50} borderRadius={8} />
      )}
      <div style={{ width: '100%', textAlign: 'center' }}>
        <Skeleton height={10} width="40%" borderRadius={8} />
      </div>
    </>
  );

  const getWidget = () => (
    <>
      {backgroundColor && iconName && (
        <IconWrapper
          color={backgroundColor}
          iconName={iconName}
          size={size}
          shape={shape}
        />
      )}
      <Label
        fontSize={labelSize ? labelSize : direction === 'row' ? '12' : '8'}
        semibold={direction === 'row'}
        textAlign="center"
        color={labelColor}
      >
        {name}
      </Label>
    </>
  );

  return (
    <FlexContainer
      className={className}
      data-test={testId}
      flexDirection={direction}
      alignItems="center"
      rowGap={direction === 'column' ? '0.8' : '0'}
      columnGap={direction === 'row' ? '1.2' : '0'}
      onClick={onClick}
    >
      {loading ? getLoadingWidget() : getWidget()}
    </FlexContainer>
  );
};
