import { FlexContainer, setColorTheme } from '@facephi/ui-react';
import styled from 'styled-components/macro';

export const CardUpgradeStyles = styled(FlexContainer)<{ disabled?: boolean }>`
  padding: 3rem;
  background-color: ${setColorTheme('white')};
  border-radius: 0.8rem;
  ${(props) =>
    props.disabled &&
    `
    p {
      color: ${setColorTheme('grey200')}
    }
    `};
`;

export const UpgradeGrid = styled.div<{ itemsLength: number }>`
  display: grid;
  grid-template-columns: repeat(
    ${(props) => (props.itemsLength < 3 ? props.itemsLength : 3)},
    25rem
  );
  grid-gap: 2.4rem;
  margin: 2rem 0;
`;
