import { gql } from '@apollo/client';

export const updateUser = gql`
  mutation updateUser($user: PatchUser!, $userId: String!) {
    user: patchUser(user: $user, userId: $userId) {
      personalInformation {
        avatar
        email
        firstName
        lastName
      }
      userPreferences {
        language
        timezone
      }
      groups {
        id
        name
      }
      role {
        id
        name
      }
    }
  }
`;

export const createUser = gql`
  mutation createUser($user: NewUser!) {
    user: createUser(user: $user) {
      id
      username
    }
  }
`;

export const createGroup = gql`
  mutation createGroup($name: String!, $users: [String!], $tenantId: String!) {
    group: createGroup(
      group: { name: $name }
      usersIds: $users
      tenantId: $tenantId
    ) {
      id
    }
  }
`;

export const deleteUserMutation = gql`
  mutation deleteUser($userId: String!) {
    deleteUser(userId: $userId)
  }
`;

export const deleteGroupMutation = gql`
  mutation deleteGroup($groupId: String!, $tenantId: String!) {
    deleteGroup(groupId: $groupId, tenantId: $tenantId)
  }
`;

export const assignRoles = gql`
  mutation assignRoles($roleId: String!, $usersId: [String!]!) {
    assignRoles(roleId: $roleId, usersId: $usersId) {
      users {
        id
      }
    }
  }
`;
