export type OperationsFilter = {
  text?: string;
  fromTimeStamp?: string;
  status?: OperationStatus;
  family?: OperationType;
};

export enum OperationStatus {
  started = 'STARTED',
  denied = 'DENIED',
  expired = 'EXPIRED',
  blacklisted = 'BLACKLISTED',
  succeeded = 'SUCCEEDED',
  cancelled = 'CANCELLED',
  error = 'ERROR',
}

export type OperationResultDto = {
  status: OperationStatus;
  reason: string;
  type: string;
};

export enum StatusMessages {
  'SUCCEEDED' = 'The operation has been approved on',
  'DENIED' = 'The operation has been rejected on',
}

export enum OperationType {
  onboarding = 'ONBOARDING',
  authentication = 'AUTHENTICATION',
}

export type ErrorLiteralType = {
  title: string;
  description: string;
};

export enum OperationResultReason {
  agoraCancelByUser = 'AGORA_CANCEL_BY_USER',
  agoraInternalError = 'AGORA_INTERNAL_ERROR',
  bannedListsError = 'BANNED_LISTS_ERROR',
  bannedListsNotPassed = 'BANNED_LISTS_NOT_PASSED',
  blackListedFaceTemplate = 'BLACKLISTED_FACE_TEMPLATE',
  countryVsLocationNotPAssed = 'COUNTRY_VS_LOCATION_NOT_PASSED',
  documentValidationError = 'DOCUMENT_VALIDATION_ERROR',
  documentValidationNotPassed = 'DOCUMENT_VALIDATION_NOT_PASSED',
  facialAuthenticationError = 'FACIAL_AUTHENTICATION_ERROR',
  facialAuthenticationNotPassed = 'FACIAL_AUTHENTICATION_NOT_PASSED',
  facialLivenessError = 'FACIAL_LIVENESS_ERROR',
  facialLivenessNotPassed = 'FACIAL_LIVENESS_NOT_PASSED',
  governmentalServiceError = 'GOVERNMENTAL_SERVICE_ERROR',
  governmentalServiceNotPassed = 'GOVERNMENTAL_SERVICE_NOT_PASSED',
  nfcCancelByUser = 'NFC_CANCEL_BY_USER',
  nfcInternalError = 'NFC_INTERNAL_ERROR',
  nfcTimeout = 'NFC_TIMEOUT',
  phacturaCaptureCancelByUser = 'PHACTURA_CAPTURE_CANCEL_BY_USER',
  phacturaCaptureInternalError = 'PHACTURA_CAPTURE_INTERNAL_ERROR',
  phacturaCaptureTimeout = 'PHACTURA_CAPTURE_TIMEOUT',
  phingersCancelByUser = 'PHINGERS_CANCEL_BY_USER',
  phingersInternalError = 'PHINGERS_INTERNAL_ERROR',
  phingersTimeout = 'PHINGERS_TIMEOUT',
  qrCaptureCancelByUser = 'QR_CAPTURE_CANCEL_BY_USER',
  qrCaptureInternalError = 'QR_CAPTURE_INTERNAL_ERROR',
  qrCaptureTimeout = 'QR_CAPTURE_TIMEOUT',
  sdkMobileError = 'SDK_MOBILE_ERROR',
  securityServiceError = 'SECURITY_SERVICE_ERROR',
  securityServiceNotPassed = 'SECURITY_SERVICE_NOT_PASSED',
  selphiCancelByUser = 'SELPHI_CANCEL_BY_USER',
  selphiInternalError = 'SELPHI_INTERNAL_ERROR',
  selphiTimeout = 'SELPHI_TIMEOUT',
  selphidCancelByUser = 'SELPHID_CANCEL_BY_USER',
  selphidInternalError = 'SELPHID_INTERNAL_ERROR',
  selphidTimeout = 'SELPHID_TIMEOUT',
  videoCallCancelByUser = 'VIDEO_CALL_CANCEL_BY_USER',
  videoCallInternalError = 'VIDEO_CALL_INTERNAL_ERROR',
  videoCallTimeout = 'VIDEO_CALL_TIMEOUT',
  videoIdCancelByUser = 'VIDEO_ID_CANCEL_BY_USER',
  videoIdInternalError = 'VIDEO_ID_INTERNAL_ERROR',
  videoIdTimeout = 'VIDEO_ID_TIMEOUT',
  videoRecordingCancelByUser = 'VIDEO_RECORDING_CANCEL_BY_USER',
  videoRecordingInternalError = 'VIDEO_RECORDING_INTERNAL_ERROR',
  videoRecordingTimeout = 'VIDEO_RECORDING_TIMEOUT',
  voiceAuthenticactionError = 'VOICE_AUTHENTICATION_ERROR',
  voiceAuthenticationNotPassed = 'VOICE_AUTHENTICATION_NOT_PASSED',
  voiceCancelByUser = 'VOICE_CANCEL_BY_USER',
  voiceIdCancelByUser = 'VOICE_ID_CANCEL_BY_USER',
  voiceIdInternalError = 'VOICE_ID_INTERNAL_ERROR',
  voiceIdTimeout = 'VOICE_ID_TIMEOUT',
  voiceInternalError = 'VOICE_INTERNAL_ERROR',
  voiceRegisterError = 'VOICE_REGISTER_ERROR',
  voiceTimeout = 'VOICE_TIMEOUT',
}

export enum OperationResultReasonTypes {
  timeout = 'TIMEOUT',
  cancelByUser = 'CANCEL_BY_USER',
  error = 'ERROR',
  notPassed = 'NOT_PASSED',
  alreadyRegistered = 'ALREADY_REGISTERED',
  manualStatusChange = 'MANUAL_STATUS_CHANGE',
  expiredByScheduler = 'EXPIRED_BY_SCHEDULER',
}

export const OperationReasonsTexts: {
  [key in string]: ErrorLiteralType;
} = {
  [OperationResultReason.agoraCancelByUser]: {
    title: 'Agora canceled',
    description: 'User canceled the operation',
  },
  [OperationResultReason.agoraInternalError]: {
    title: 'Agora error',
    description: 'Internal error during video assistance',
  },
  [OperationResultReasonTypes.alreadyRegistered]: {
    title: 'User already registered',
    description: 'User was already registered in the database',
  },
  [OperationResultReason.bannedListsError]: {
    title: 'Blocklist error',
    description: 'Internal error with the blocked people list',
  },
  [OperationResultReason.bannedListsNotPassed]: {
    title: 'On blocklist',
    description: 'User is on the blocked list',
  },
  [OperationResultReason.blackListedFaceTemplate]: {
    title: '',
    description: '',
  },
  [OperationResultReasonTypes.cancelByUser]: {
    title: '',
    description: 'User canceled the operation',
  },
  [OperationResultReason.countryVsLocationNotPAssed]: {
    title: 'Country vs location rejected',
    description:
      'The nationality of the document and the location of the user are different',
  },
  [OperationResultReason.documentValidationError]: {
    title: 'Document validation error',
    description: 'Internal error during face and document comparative',
  },
  [OperationResultReason.documentValidationNotPassed]: {
    title: 'Document validation rejected',
    description: 'User face and document dont match',
  },
  [OperationResultReasonTypes.expiredByScheduler]: {
    title: '',
    description: '',
  },
  [OperationResultReason.facialAuthenticationError]: {
    title: 'Facial authentication error',
    description: 'Internal error during facial authentication',
  },
  [OperationResultReason.facialAuthenticationNotPassed]: {
    title: 'Facial authentication rejected',
    description: 'User face doesnt match with facial patterns',
  },
  [OperationResultReason.facialLivenessError]: {
    title: 'Liveness error',
    description: 'Internal error during user liveness checking',
  },
  [OperationResultReason.facialLivenessNotPassed]: {
    title: 'Liveness rejected',
    description: 'User was not identified as a living person',
  },
  [OperationResultReason.governmentalServiceError]: {
    title: 'Governmental validation error',
    description: 'Internal error checking governmental database',
  },
  [OperationResultReason.governmentalServiceNotPassed]: {
    title: 'Governmental validation rejected',
    description: 'User is not admitted according to the government service',
  },
  [OperationResultReasonTypes.manualStatusChange]: {
    title: '',
    description: '',
  },
  [OperationResultReason.nfcCancelByUser]: {
    title: 'NFC canceled',
    description: 'User canceled the operation',
  },
  [OperationResultReason.nfcInternalError]: {
    title: 'NFC error',
    description: 'Internal error capturing NFC',
  },
  [OperationResultReason.nfcTimeout]: {
    title: 'NFC timeout',
    description: 'Time exceeded capturing NFC',
  },
  [OperationResultReason.phacturaCaptureCancelByUser]: {
    title: 'Bill canceled',
    description: 'User canceled the operation',
  },
  [OperationResultReason.phacturaCaptureInternalError]: {
    title: 'Phactura error',
    description: 'Internal error capturing bill',
  },
  [OperationResultReason.phacturaCaptureTimeout]: {
    title: 'Bill timeout',
    description: 'Time exceeded capturing bill',
  },
  [OperationResultReason.phingersCancelByUser]: {
    title: 'Fingerprint capture canceled',
    description: 'User canceled the operation',
  },
  [OperationResultReason.phingersInternalError]: {
    title: 'Phingers error',
    description: 'Internal error capturing fingerprint.',
  },
  [OperationResultReason.phingersTimeout]: {
    title: 'Fingerprint timeout',
    description: 'User canceled the operation',
  },
  [OperationResultReason.qrCaptureCancelByUser]: {
    title: 'QR canceled',
    description: 'User canceled the operation',
  },
  [OperationResultReason.qrCaptureInternalError]: {
    title: 'QR error',
    description: 'Internal error capturing QR',
  },
  [OperationResultReason.qrCaptureTimeout]: {
    title: 'QR timeout',
    description: 'Time exceeded capturing QR',
  },
  [OperationResultReason.sdkMobileError]: {
    title: 'SDK Error',
    description: 'Generic SDK error',
  },
  [OperationResultReason.securityServiceError]: {
    title: 'Security service error',
    description: 'Internal error checking the security service',
  },
  [OperationResultReason.securityServiceNotPassed]: {
    title: 'Security service rejected',
    description: 'Morphological validation was rejected',
  },
  [OperationResultReason.selphiCancelByUser]: {
    title: 'Facial capture canceled',
    description: 'User canceled the operation',
  },
  [OperationResultReason.selphiInternalError]: {
    title: 'Facial capture error',
    description: 'Internal error during capture',
  },
  [OperationResultReason.selphiTimeout]: {
    title: 'Facial capture timeout',
    description: 'Time exceeded during capture',
  },
  [OperationResultReason.selphidCancelByUser]: {
    title: 'Document capture canceled',
    description: 'User canceled the operation',
  },
  [OperationResultReason.selphidInternalError]: {
    title: 'Document capture error',
    description: 'Internal error during capture',
  },
  [OperationResultReason.selphidTimeout]: {
    title: 'Document capture timeout',
    description: 'Time exceeded during capture',
  },
  [OperationResultReason.videoCallCancelByUser]: {
    title: 'VideoCall canceled',
    description: 'User canceled the operation',
  },
  [OperationResultReason.videoCallInternalError]: {
    title: 'VideoCall error',
    description: 'Internal error during videocall',
  },
  [OperationResultReason.videoCallTimeout]: {
    title: 'VideoCall timeout',
    description: 'Time exceeded during videocall',
  },
  [OperationResultReason.videoIdCancelByUser]: {
    title: 'VideoID canceled',
    description: 'User canceled the operation',
  },
  [OperationResultReason.videoIdInternalError]: {
    title: 'VideoID error',
    description: 'Internal error during video identification',
  },
  [OperationResultReason.videoIdTimeout]: {
    title: 'VideoID timeout',
    description: 'Time exceeded during video identification',
  },
  [OperationResultReason.videoRecordingCancelByUser]: {
    title: 'VideoRecording canceled',
    description: 'User canceled the operation',
  },
  [OperationResultReason.videoRecordingInternalError]: {
    title: 'VideoRecording error',
    description: 'Internal error during video recording',
  },
  [OperationResultReason.videoRecordingTimeout]: {
    title: 'VideoRecording timeout',
    description: 'Time exceeded during video recording',
  },
  [OperationResultReason.voiceAuthenticactionError]: {
    title: '',
    description: '',
  },
  [OperationResultReason.voiceAuthenticationNotPassed]: {
    title: '',
    description: '',
  },
  [OperationResultReason.voiceCancelByUser]: { title: '', description: '' },
  [OperationResultReason.voiceIdCancelByUser]: {
    title: 'VoiceID canceled',
    description: 'User canceled the operation',
  },
  [OperationResultReason.voiceIdInternalError]: {
    title: 'VoiceID error',
    description: 'Internal error during voice identification',
  },
  [OperationResultReason.voiceIdTimeout]: {
    title: 'VoiceID timeout',
    description: 'Time exceeded during voice identification',
  },
  [OperationResultReason.voiceInternalError]: { title: '', description: '' },
  [OperationResultReason.voiceRegisterError]: { title: '', description: '' },
  [OperationResultReason.voiceTimeout]: { title: '', description: '' },
};

export type ServiceManualCheck = {
  type: string;
  name: string;
  isChecked: boolean;
};

type OperationResult = {
  type: string | null;
  status: OperationStatus;
  reason: OperationResultReason | null;
};

export type StatusChange = {
  type: string;
  status: OperationStatus;
  comment: string | null;
  previousStatus: OperationStatus;
  timestamp: string | number | Date;
};

export type DetailsType = {
  operationResults: OperationResult[] | null;
  statusChangeList: StatusChange[] | null;
  type: OperationType;
  extServiceManualChecklist: ServiceManualCheck[] | null;
};

export type OperationDetailsDto = {
  operationId: string;
  type: OperationType;
  details: DetailsType;
};

// TO DO -> Used to hide manual validations provider name
// adjustment required when backend sends different payloads for securityInfoData
export const manualValidations = ['veriff', 'jumio'];
