import { gql } from '@apollo/client';

export const getUsers = gql`
  query searchUsers($username: String, $cursor: String) {
    listUsers: searchUsers(
      after: $cursor
      parameters: { username: $username }
    ) {
      users: edges {
        node {
          username
          id
          status
          personalInformation {
            avatar
            email
          }
          groups {
            id
            name
          }
          role {
            id
            name
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const getUser = gql`
  query getUser($userId: String!) {
    user: getUser(userId: $userId) {
      personalInformation {
        avatar
        email
        firstName
        lastName
      }
      userPreferences {
        language
        timezone
      }
      groups {
        id
        name
      }
      role {
        id
        name
      }
    }
  }
`;

export const getGroups = gql`
  query searchGroups($tenantId: String!) {
    listGroups: searchGroups(tenantId: $tenantId) {
      groups {
        id
        name
        description
        tenantId
      }
    }
  }
`;

export const getUsersForGroup = gql`
  query searchUsersForGroup($groupId: String!, $tenantId: String!) {
    listUsers: searchUsersForGroup(groupId: $groupId, tenantId: $tenantId) {
      users {
        id
        username
        personalInformation {
          avatar
        }
      }
    }
  }
`;

export const getRoles = gql`
  query getRoles {
    listRoles: getRoles {
      roles {
        id
        name
      }
    }
  }
`;
